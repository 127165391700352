import MedicalPlusTurismo from "./imgs/c-icono_pasajero.png";
import BolsoProtegido from "./imgs/c-icono_bolso_protegido.png";
import Universal from "./imgs/c-icono_asistencia-viajero-mundo.png";
export const listProducts = ["MedicalPlusTurismo", "BolsoProtegido"];
export const externalProducts = ["AsistenciaViajero"];
export const infoProducts = {
  MedicalPlusTurismo: {
    title: "Medical Plus Turismo",
    img: MedicalPlusTurismo,
    path: "medical-plus-turismo",
    subtitle:
      "Te brindamos cobertura para afrontar los gastos de internación por padecer COVID-19 durante tu viaje en Argentina o el exterior. Desde $945",
  },
  BolsoProtegido: {
    title: "Bolso Protegido",
    img: BolsoProtegido,
    path: "bolso-protegido",
    subtitle: "Te brindamos cobertura para proteger tu bolso desde $270",
  },
};
export const infoExternalProducts = {
  AsistenciaViajero: {
    title: "Asistencia al viajero",
    img: Universal,
    path: "https://www.universal-assistance.com/ar-es/flechabus?utm_source=rus_ar_affinity&utm_medium=referral&utm_campaign=lp_flechabus_ar",
    subtitle: "Tu viaje es tu viaje. Nosotros te lo protegemos.",
  },
};
