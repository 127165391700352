import { HttpClientCustomService } from './httpclientcustom.service.js';

export default class productService {
    httpClientBase;

    constructor() {
        this.httpClientBase = new HttpClientCustomService()
    }

    getProduct(data, pageSearch, token) {
        return this.httpClientBase
            .get(`/product/searchProducts/${data}/${pageSearch}`, token);
    };

    getProductsForDate(date, token) {
        return this.httpClientBase
            .get(`/product/productsForDate/${date}`, token)
    }

    getMyProductsSale(id_user, page, token) {
        return this.httpClientBase
            .get(`/product/getMyProductsSale/${id_user}/${page}`, token);
    }

    getCountProductNotis(token) {
        return this.httpClientBase
            .get(`/product/getCountNotis`, token)
    }

    getCountProductNotisAdmin(token) {
        return this.httpClientBase
            .get(`/product/getCountNotisAdmin`, token)
    }

    getAllOrders(page, token) {
        return this.httpClientBase
            .get(`/product/getAllOrders/${page}`, token)
    }

    getClientOrders(page, token) {
        return this.httpClientBase
            .get(`/product/getOrderByUser/${page}`, token)
    }

    putIssureOrder(item, token) {
        return this.httpClientBase
            .put(`/product/sendPoliza`, item, token)
    }
    putDisableOrder(item, token) {
        return this.httpClientBase
            .put(`/product/disablePoliza`, item, token)
    }

    getNumnerOfClient(token) {
        return this.httpClientBase
            .get(`/product/numberOfClients`, token)
    }

    getNumberOfOrders(token) {
        return this.httpClientBase
            .get(`/product/numberOfOrders`, token)
    }

    getProducts(page, token) {
        return this.httpClientBase
        .get(`/product/${page}`, token)
    }

    getProductsByGroup(productgroup, token) {
        return this.httpClientBase
        .get(`/product/bygroup/${productgroup}`, token)
    }

    updateProducts(id, data, token) {
        return this.httpClientBase
        .put(`/product/${id}`, data, token)
    }

    postProducts(data, token) {
        return this.httpClientBase
        .post(`/product/`, data, token)
    }

    deleteProducts(id,token) {
        return this.httpClientBase
        .delete(`/product?id=${id}`, token)
    }

    loadPdf(formData,cert,token) {
        return this.httpClientBase
        .post(`/product/loadpdf?id=${cert}`, formData, token)
    }

    postProductsGroups(data, token) {
        return this.httpClientBase
        .post(`/product/productgroup`, data, token)
    }

    deleteProductsGroups(id,token) {
        return this.httpClientBase
        .delete(`/product/productgroup/${id}`, token)
    }

    getProductsGroups(page, token) {
        return this.httpClientBase
        .get(`/product/productgroup/list/${page}`, token)
    }

    getProductsPas(userId) {
        return this.httpClientBase
        .get(`/product/getProductsPas/${userId}`)
    }

    getAllProductsGroups(token) {
        return this.httpClientBase
        .get(`/product/productgroup/all`, token)
    }
}
