import React, { useEffect } from "react";
import useUser from "../../../../hooks/UseUser";
import "react-toastify/dist/ReactToastify.css";
import { URLSERVER } from "../../../../config/config";
import InfiniteScroll from 'react-infinite-scroll-component';
import notificationService from "../../../../services/notification.service";

export const TableNotiClient = ({
  notis,
  setPage,
  loading,
  showShowMore,
  loadingNextPage,
  setLoading,
  setLoadingNextPage,
  setNotis,
  page,
  setShowShowMore,
  deleteNoti,
}) => {
  const service = new notificationService()
  const { jwt } = useUser();

  useEffect(() => {
    if (!notis?.length) {
      setLoading(true);
    }
    setLoadingNextPage(true);
    service.getNotification(page, '', jwt).subscribe((s) => {
      if (s.length === 0 || s.length < 6) setShowShowMore(false);
      setLoading(false);
      setLoadingNextPage(false);
      setNotis(notis.concat(s.data));
    });
  }, [page]);

  return (
    <div className="w-100 h-100 pre" id="InfiniteScroll">
      <InfiniteScroll 
      dataLength={notis.length} 
      next={() => {setPage(prev=>prev+1)}}
      hasMore={true}
      scrollableTarget="InfiniteScroll"
      >
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Descripción</th>
            <th scope="col">Eliminar Notificación</th>
          </tr>
        </thead>
        {notis?.length ? (
          <tbody>
            {notis?.map((e) => (
              <tr key={e?.id}>
                <td>{e?.description}</td>
                <td>
                  <strong
                    style={{ color: "#dc3545", cursor: "pointer" }}
                    onClick={() => deleteNoti(e?.id, "")}
                  >
                    Eliminar
                  </strong>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="2" className="text-center">
                <strong className="empty">Nada nuevo por aquí!</strong>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      </InfiniteScroll>
    </div>
  );
};
