import { HttpClientCustomService } from './httpclientcustom.service.js';

export default class orderService {
    httpClientBase;

    constructor (
        httpClient,
    ) {
        this.httpClientBase = new HttpClientCustomService(
            httpClient,
        )
    }

    postOrderFlecha(data, token) {
        return  this.httpClientBase
          .post(`/product/postOrderFlecha`, data, token);
    }

    postEmitBPPas(data, token) {
        return  this.httpClientBase
          .post(`/product/emitBPPas`, data, token);
    }

    postCreatePlan(data, token) {
        return  this.httpClientBase
          .post(`/mp/create-plan`, data, token);
    }
}
