import React from 'react';
import { Icon } from '@iconify/react';
import './index.css'

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent("¡Hola!, quiero un producto que no está en la página.");
    const whatsappNumber = process.env.REACT_APP_WHATSAPPNUMBER
    const whatsappURL = `https://wa.me/+549${whatsappNumber}?text=${message}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className="wpp-button">
      <button onClick={handleWhatsAppClick}>
        <p className='wpp-text'>Buscás un producto <br /> y no está en la página?</p><Icon className='wpp-icon' icon="ic:baseline-whatsapp" />
      </button>
    </div>
  );
};

export default WhatsAppButton;