import React, { useState } from "react";
import UserInfoRow from "./UserInfoRow";
import { SearchbarTable } from "../../Searchbar/SearchbarTable";
import LoaderSpinner from "../../../LoaderSpinner/LoaderSpinner";
import NoResultsText from "../../../NoResultsText/NoResultsText";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCallback } from "react";
import useUser from "../../../../hooks/UseUser.js";
import userService from "../../../../services/user.service.js";
import { useEffect } from "react";

export const TableSearchUser = () => {
  const [page, setPage] = useState(1);
  const [input, setInput] = useState("");
  const [users, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const { jwt } = useUser();
   const serviceUser = new userService()


  const fetchAllUsers = useCallback(() => {
    setLoading(true);
    serviceUser.getAllUsers(page, jwt).subscribe((s) => {
      setLoading(false);
      setUser(users.concat(s.data.users));
    });
  }, [jwt, page,input]);

  const handleSubmitSearch = useCallback(
    (input) => {
      if (input.trim().length) {
        serviceUser.getUserByEmail(input.trim(), jwt).subscribe((s) => {
          setUser(s.data);
        });
      }
    },
    [jwt]
  );

  useEffect(() => {
    if (input.trim().length) handleSubmitSearch(input);
    else fetchAllUsers();
  }, [page, input, handleSubmitSearch, fetchAllUsers]);

  return (
    <div className="w-100 h-100 pre" id="InfiniteScroll">
      <div className="flex justify-between-l justify-center pv2">
        <SearchbarTable
          setInput={setInput}
          handleSubmitSearch={handleSubmitSearch}
          input={input}
        />
      </div>
      <InfiniteScroll
        dataLength={users.length}
        next={() => setPage((prev) => prev + 1)}
        hasMore={true}
        scrollableTarget="InfiniteScroll"
      >
        <table>
          <thead>
            <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope="col">Email</th>
            <th scope="col">Tipo usuario</th>
            <th scope="col">Cambiar a</th>
          </thead>
          <tbody>
            {users?.map((e) => (
              <UserInfoRow e={e} fetchAllUsers={fetchAllUsers}/>
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
      {loading && <LoaderSpinner />}
      {!loading && !users.length && <NoResultsText />}
    </div>
  );
};
