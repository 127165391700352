import React, { useState } from "react";
import useUser from "../../../../hooks/UseUser";
import { TableNotisAdmin } from "./TableNotisAdmin";
import { TableNotiPas } from "./TableNotiPas";
import { TableNotiClient } from "./TableNotiClient";
import {
  userIsAdmin,
  userIsPas,
} from "../../../../utils/user-is-type";
import notificationService from "../../../../services/notification.service";


export const TableNotis = () => {
  const { user, jwt } = useUser();
  const [notis, setNotis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [showShowMore, setShowShowMore] = useState(true);
  const isAdmin = userIsAdmin(user);
  const isPas = userIsPas(user);
  const [page, setPage] = useState(1);
  const notiService = new notificationService();

  const deleteNoti = (id, table) => {
    notiService.deleteNotification(id, table, jwt).subscribe(({response}) => {
      if (response.data) {
        let info = notis.flat();
        let contador = 0;
        info.map((noti) => {
          if (noti.id === id) {
            info.splice(contador, 1);
          }
          contador++;
        });
        setNotis(info);
      }
    });
  };

  return isAdmin ? (
    <TableNotisAdmin
      notis={notis}
      setPage={setPage}
      loading={loading}
      loadingNextPage={loadingNextPage}
      showShowMore={showShowMore}
      page={page}
      setLoading={setLoading}
      setLoadingNextPage={setLoadingNextPage}
      setNotis={setNotis}
      setShowShowMore={setShowShowMore}
      deleteNoti={deleteNoti}
    />
  ) : isPas ? (
    <TableNotiPas
      notis={notis}
      setPage={setPage}
      loading={loading}
      loadingNextPage={loadingNextPage}
      showShowMore={showShowMore}
      page={page}
      setLoading={setLoading}
      setLoadingNextPage={setLoadingNextPage}
      setNotis={setNotis}
      setShowShowMore={setShowShowMore}
      deleteNoti={deleteNoti}
    />
  ) : (
    <TableNotiClient
      notis={notis}
      setPage={setPage}
      loading={loading}
      loadingNextPage={loadingNextPage}
      showShowMore={showShowMore}
      page={page}
      setLoading={setLoading}
      setLoadingNextPage={setLoadingNextPage}
      setNotis={setNotis}
      setShowShowMore={setShowShowMore}
      deleteNoti={deleteNoti}
    />
  );
};
