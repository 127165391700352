import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useUser from "../../../../hooks/UseUser";
import { URLSERVER } from "../../../../config/config";
import InfiniteScroll from 'react-infinite-scroll-component';
import notificationService from "../../../../services/notification.service";

export const TableNotisAdmin = ({
  notis,
  setPage,
  loading,
  showShowMore,
  loadingNextPage,
  setLoading,
  setLoadingNextPage,
  setNotis,
  page,
  setShowShowMore,
  deleteNoti,
}) => {
  const service = new notificationService()
  const { jwt } = useUser();

  useEffect(() => {
    if (!notis?.length) {
      setLoading(true);
    }
    setLoadingNextPage(true);
    service.getNotification(page, 'Admin', jwt).subscribe((s) => {
      if (s.length === 0 || s.length < 6) setShowShowMore(false);
      setLoading(false);
      setLoadingNextPage(false);
      setNotis(notis.concat(s.data));
    });
  }, [page]);

  return (
    <div className="w-100 h-100 pre" id="InfiniteScroll" style={{ overflowX: "auto" }}> 
      <InfiniteScroll 
      dataLength={notis.length} 
      next={() => {setPage(prev=>prev+1)}}
      hasMore={true}
      scrollableTarget="InfiniteScroll"
      >
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Nombre y Apellido</th>
            <th scope="col">Telefono</th>
            <th scope="col">Email</th>
            <th scope="col">Descripción</th>
            <th scope="col">Visitar Perfil</th>
            <th scope="col">Eliminar Notificación</th>
          </tr>
        </thead>
        {notis?.length ? (
          <tbody>
            {notis.map((e) => (
              <tr key={e.id}>
                <td>
                  <img
                    src={`${URLSERVER}/${e?.img}`}
                    style={{
                      height: 40,
                      borderRadius: 40,
                    }}
                  />
                </td>
                <td>
                  {e?.name},{e?.last_name}
                </td>
                <td>{e?.phone_number}</td>
                <td>{e?.email}</td>
                <td>{e?.description}</td>
                <td>
                  <Link
                    to={`user/${e?.idPas}`}
                    style={{
                      color: "var(--color-first-medium-light)",
                      cursor: "pointer",
                    }}
                  >
                    Ver Usuario
                  </Link>
                </td>
                <td>
                  <button
                    className="btn delete-button"
                    onClick={() => deleteNoti(e?.id, "Admin")}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="7" className="text-center">
                <strong className="empty">Nada nuevo por aquí!</strong>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      </InfiniteScroll>
    </div >
  );
};
