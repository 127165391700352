import { useEffect, useState } from "react";
import productService from "../../../../services/product.service";
import useUser from "../../../../hooks/UseUser";

export const ModalUpdateProduct = ({ title, data, handleChange, handleSelect }) => {
    const { jwt } = useUser();
    const productSrv = new productService()
    const [productsgroups, setProductsGroups] = useState([]);
    const getAllProductsGroups = ()=> {
        productSrv.getAllProductsGroups(jwt).subscribe(s => {
          setProductsGroups(s?.data)
        });
      }

    useEffect(() => {
        getAllProductsGroups()
    }, [jwt]);

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <h4>{title}</h4>
                <label>Descripción:</label>
                <input type="text" name="description" style={{ height: "25px", border: "solid 1px" }} value={data.description} onChange={(e) => handleChange(e)} />
                <label>Precio:</label>
                <input type="number" name="price" style={{ height: "25px", border: "solid 1px" }} value={data?.price} onChange={(e) => handleChange(e)} />
                <label>Suma asegurada:</label>
                <input type="number" name="sa" style={{ height: "25px", border: "solid 1px" }} value={data?.sa} onChange={(e) => handleChange(e)} />
                <label>Suma asegurada diaria:</label>
                <input type="number" name="saDaily" style={{ height: "25px", border: "solid 1px" }} value={data?.saDaily} onChange={(e) => handleChange(e)} />
                <label>Grupo:</label>
                <select onChange={(element) => handleSelect(element.target.value)}>
                        <option value="" hidden>
                            Seleccionar grupo
                        </option>
                        {productsgroups?.map((s) => (
                            <option key={s.id} value={s.id}>
                                {s.description}
                            </option>
                        ))}
                </select>
            </div>
        </>
    )
} 