export default function DashboardContent({ children, className }) {
  return (
    <div
      className={`pre br--right-4-l br4-mob w-95 w-75 h-35-l center-mob bg-white pa3${
        className ? ` ${className}` : ""
      }`}
    >
      {children}
      <style>{`
      .w-95 {
        width: 77%;
      }
      @media screen and (max-width: 60em) {
        .br4-mob {
          border-radius: 1rem;
        }
        .center-mob {
          margin-right: auto;
          margin-left: auto;
        }
      }
      @media screen and (min-width: 60em) {
        .br--right-4-l {
          border-top-right-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
        .h-35-l {
          height: 35rem;
        }
      }
        `}</style>
    </div>
  );
}
