import React, { useEffect, useState } from "react";
import useUser from "../../../../hooks/UseUser";
import LoaderSpinner from "../../../LoaderSpinner/LoaderSpinner";
import InfiniteScroll from 'react-infinite-scroll-component';
import userService from "../../../../services/user.service";
import { Modal } from "../../../Modal/Modal";
import { ModalUpdateEmiter } from "./ModalUpdateEmiter";
import { ModalDeleteEmiter } from "./ModalDeleteEmiter";
import { ModalCreateEmiter } from "./ModalCreateEmiter";

export const TableEmiter = () => {
  const { jwt, user } = useUser();
  const [emiters, setEmiters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const userServices = new userService()
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [data,setData] = useState()

  const handleClose = () =>{ 
    setShowModalUpdate(false)
    setShowModalCreate(false)
    setShowModalDelete(false)
    getDataAll()
  }

  const getData = () => {
    userServices.getEmiter(page,jwt).subscribe(s => {
        setLoading(false);
        setEmiters(emiters.concat(s?.data))
      });
  }

  const getDataAll = () => {
    userServices.getEmiter(page,jwt).subscribe(s => {
        setLoading(false);
        setEmiters(s?.data)
      });
  }

  const handleOpenModalDelete = (e, id) => {
    setData({
        emiterId: id,
        enabled: e
    })
    setShowModalDelete(true)
  }

  const handleOpenModalUpdate = (e, id) => {
    setData({
        emiterId: id,
        active: e
    })
    setShowModalUpdate(true)
  };

  const handleOpenModalCreate = () => {
    setData({
      description:"",
      price:""
    })
    setShowModalCreate(true)
  };

  const handleConfirmDelete = () => {
    userServices.deleteEmiter(data.emiterId, data.enabled ? 1 : 0, jwt).subscribe(s => {
        setLoading(false);
      });
  }

  const handleConfirmUpdate = () => {
    userServices.putEmiter(data,jwt).subscribe(s => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    getData()
  }, [jwt, page, user.id_user]);

  return (
  <>
    {
    showModalUpdate && 
          <Modal handleClose={handleClose} handleConfirm={handleConfirmUpdate} cruzButton>
            <ModalUpdateEmiter title={data}/>
          </Modal>
    }
    {
    showModalCreate && 
          <Modal handleClose={handleClose} cruzButton>
            <ModalCreateEmiter/>
          </Modal>
    }
    {
    showModalDelete && 
          <Modal handleClose={handleClose} handleConfirm={handleConfirmDelete} cruzButton>
            <ModalDeleteEmiter data={data}/>
          </Modal>
    }
    <div className="w-100 h-100 pre" id="InfiniteScroll" style={{ overflowX: "auto" }}>
      <InfiniteScroll 
      dataLength={emiters.length} 
      next={() => {setPage(prev=>prev+1)}}
      hasMore={true}
      scrollableTarget="InfiniteScroll"
      >
        <button className="button success-button br-none mh2" onClick={()=>handleOpenModalCreate()}>
          <b>Crear emisor</b>
        </button>
      <table>
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope="col">Email</th>
            <th scope="col">Active</th>
            <th scope="col">Habilitado</th>
          </tr>
        </thead>
        {emiters.length ? (
          <tbody>
            {emiters.map((e, i) => (
              <tr key={i}>
                <td>{e?.name}</td>
                <td>{e?.last_name}</td>
                <td>{e?.email}</td>
                <td onClick={()=>handleOpenModalUpdate(!e?.active, e?.emiterId)}><button className={e?.active ? "button success-button br-none mh2" : "button delete-button br-none mh2"}>{e?.active ? "En linea" : "Fuera de linea"}</button></td>
                <td onClick={()=>handleOpenModalDelete(!e?.enabled, e?.emiterId)}><button className={e?.enabled ? "button success-button br-none mh2" : "button delete-button br-none mh2"}>{e?.enabled ? "Activo" : "Desactivado"}</button></td>
              </tr>
            ))}
          </tbody>
        ) : (
          <></>
        )}
      </table>
      </InfiniteScroll>
       {loading && <LoaderSpinner />}
    </div>
  </>
  );
};
