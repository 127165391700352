import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import {
  faBell,
  faArrowRightFromBracket,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import productService from "../../../services/product.service";
import useUser from "../../../hooks/UseUser";

export const SideBarClient = ({ logout, updateSite }) => {
  const [selectedArea, setSelectedArea] = useState("profile");
  const { jwt } = useUser();
  const [notis, setNotis] = useState(0);
  const productServices = new productService();

  const selectItem = (valor) => {
    setSelectedArea(valor);
  };

  useEffect(() => {
    if(jwt){
      productServices
      .getCountProductNotis(jwt)
      .subscribe(({data}) =>{
        setNotis(data.total_records);
      });
    }
  }, []);
  return (
    <>
      <ul>
        <p className="centerP">Listas</p>
        <li
          onClick={() => {
            updateSite("compras");
            selectItem("compras");
          }}
          className={`${selectedArea === "compras" ? "selected " : ""}flex`}
        >
          <div className="w-100">
            <FontAwesomeIcon
              icon={faCartShopping}
              className={selectedArea === "compras" ? "pl1" : ""}
            />
            <label className="text">Mis órdenes</label>
          </div>
        </li>
        <p className="centerP">Útil</p>
        <li
          onClick={() => {
            updateSite("notificaciones");
            selectItem("notificaciones");
          }}
          className={`${
            selectedArea === "notificaciones" ? "selected " : ""
          }flex relative`}
        >
          {notis ? (
            <div
              style={{
                backgroundColor: "#dc3545",
                height: "12px",
                minWidth: 10,
                position: "absolute",
                borderRadius: "100%",
                left: "12px",
                fontSize: 10,
                top: "4px",
                textAlign: "center",
              }}
            >
              {notis < 10 ? notis : "+10"}
            </div>
          ) : (
            <></>
          )}
          <div className="w-100">
            <FontAwesomeIcon
              icon={faBell}
              className={selectedArea === "notificaciones" ? "pl1" : ""}
            />
            <label className="text">Notificaciones</label>
          </div>
        </li>
        <p className="centerP">Usuario</p>

        <li
          onClick={() => {
            updateSite("profile");
            selectItem("profile");
          }}
          className={`${
            selectedArea === "profile" ? "selected " : ""
          }flex relative`}
        >
          <div className="w-100">
            <FontAwesomeIcon
              icon={faCircleUser}
              className={selectedArea === "profile" ? "pl1" : ""}
            />
            <label className="text">Perfil</label>
          </div>
        </li>
        <li onClick={logout} className="flex-l dn logout-option">
          <FontAwesomeIcon icon={faArrowRightFromBracket} />
          <div>Cerrar Sesión</div>
        </li>
      </ul>
    </>
  );
};
