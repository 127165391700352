import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef, useState } from "react";
import useUser from "../../../../hooks/UseUser";
import productService from "../../../../services/product.service";

export const DownloadExcel = () => {
  const tableRef = useRef(null);
  const [products, setProducts] = useState([]);
  const fechaActual = new Date();
  fechaActual.setDate(fechaActual.getDate() - 7);
  const año = fechaActual.getFullYear();
  const mes = String(fechaActual.getMonth() + 1).padStart(2, "0");
  const día = String(fechaActual.getDate()).padStart(2, "0");
  const fechaHace7Dias = `${año}-${mes}-${día}`;
  const { jwt } = useUser();
  const productServices = new productService()

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `ordenes de los ultimos ${fechaHace7Dias}`,
    sheet: "ordenes",
  });

  const getProducts = () => {

    productServices.getProductsForDate(fechaHace7Dias, jwt)
    .subscribe((s)=>{
      setProducts(s.data);
      onDownload();
    });
  };

  return (
    <>
      <button className="button success-button br-none mh2" onClick={getProducts}>
        <b>Export excel</b>
      </button>

      <table ref={tableRef} className="dn">
        <thead>
          <tr>
            <th>Fecha y hora</th>
            <th>Nombre y Apellido</th>
            <th>Telefono</th>
            <th>Email</th>
            <th>Certificado</th>
            <th>Tipo de poliza</th>
            <th>Precio</th>
            <th>Id de poliza</th>
            <th>Estado</th>
            <th>Emisión</th>
            <th>Póliza</th>
          </tr>
        </thead>
          {!products?.length ? (
            <></>
          ) : (
            <tbody>
            {products.map((e, i) => {
              return (
                <tr key={i}>
                  <td>{e.date_emit}</td>
                  <td>
                    {e?.name}, {e?.last_name}
                  </td>
                  <td>{e.phone_number ? e?.phone_number : "Sin número"}</td>
                  {e.email ? <td>{e?.email}</td> : <td>Sin email</td>}
                  <td>
                    {e.pathcertificate ? e.pathcertificate : "no emitido"}
                  </td>
                  <td>{e?.type} </td>
                  <td>$ {e?.price}</td>
                  <td>{e.nr_cert}</td>
                  <td>{e.active === 1 ? "Activo" : "Inactivo"}</td>
                  <td>{e.emited === 1 ? "Emitida" : "No emitida"}</td>
                  <td>{e?.poliza_name ? e.poliza_name : "no emitida"}</td>
                </tr>
              );
            })}
            </tbody>
          )}
      </table>
    </>
  );
};
