import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import useUser from "../../../../hooks/UseUser";
import LoaderSpinner from "../../../LoaderSpinner/LoaderSpinner";
import InfiniteScroll from 'react-infinite-scroll-component';
import userService from "../../../../services/user.service";

export const TableClient = () => {
  const { jwt, user } = useUser();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const userServices = new userService()

  useEffect(() => {
    setLoading(true);
    userServices.getClientsOfPas(page,jwt).subscribe(s => {
      setLoading(false);
      setClients(clients.concat(s?.data))
    });
  }, [jwt, page, user.id_user]);

  return (
    <div className="w-100 h-100 pre" id="InfiniteScroll" style={{ overflowX: "auto" }}>
      <InfiniteScroll 
      dataLength={clients.length} 
      next={() => {setPage(prev=>prev+1)}}
      hasMore={true}
      scrollableTarget="InfiniteScroll"
      >
      <table>
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope="col">Email</th>
            <th scope="col">Telefono</th>
          </tr>
        </thead>
        {clients.length ? (
          <tbody>
            {clients.map((e, i) => (
              <tr key={i}>
                <td>{e?.name}</td>
                <td>{e?.last_name}</td>
                <td>{e?.email}</td>
                <td>
                  {e?.phone_number ? (
                    <a
                      href={`https://wa.me/+549${e?.phone_number}`}
                      target="_blank"
                      className="flex justify-center items-center"
                      rel="noreferrer"
                    >
                      <Icon icon="ic:baseline-whatsapp" height="24" />
                      <p>{e.phone_number}</p>
                    </a>
                  ) : (
                    "Sin número"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <></>
        )}
      </table>
      </InfiniteScroll>
       {loading && <LoaderSpinner />}
    </div>
  );
};
