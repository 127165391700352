import "./Map.css";

export default function MapLibra({ location, attention_hours }) {
  return (
    <section id="contacto">
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d903.3641722773473!2d-58.251241965743155!3d-32.48830001946712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95afdb005ddc5d99%3A0x4e43903014de8a5b!2sR%C3%ADo%20Uruguay%20Seguros%20Concepcion%20del%20Uruguay!5e0!3m2!1ses!2sar!4v1697216055259!5m2!1ses!2sar"
          width="600"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="">
          <p></p>
          <p></p>
        </div>
      </div>
    </section>
  );
}
