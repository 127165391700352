import { SideBar } from "../../components/DashboardComponents/SideBar/SideBar";
import "./dash.css";
import "./table.css";
import useUser from "../../hooks/UseUser";
import { TableClient } from "../../components/DashboardComponents/Tables/TableClient/TableClient";
import { TableNotis } from "../../components/DashboardComponents/Tables/TableNotis/TableNotis";
import { TableSearchUser } from "../../components/DashboardComponents/Tables/TableSearchUser/TableSearchUser";
import { TableOrders } from "../../components/DashboardComponents/Tables/TableOrders/TableOrders";
import { MyUser } from "../MyUser/MyUser";
import DashboardContainer from "../../components/DashboardContainer/DashboardContainer";
import DashboardContent from "../../components/DashboardContent/DashboardContent";
import { Panel } from "./Panel";
import { TableProducts } from "../../components/DashboardComponents/Tables/TableProducts/TableProducts";
import { TableEmiter } from "../../components/DashboardComponents/Tables/TableEmiters/TableEmiters";
import { TableProductsGroups } from "../../components/DashboardComponents/Tables/TableProductsGroups/TableProductsGroups";
import { TableHopper } from "../../components/DashboardComponents/Tables/TableHopper/TableHopper";

export const Dashboard = () => {
  const { site, user } = useUser();

  return (
    <DashboardContainer>
      <SideBar/>
      <DashboardContent className="ml2-l">
        {user.type === "pas" ||
        user.type === "admin" ||
        user.type === "superadmin" ? (
          site === "panel" ? (
            <Panel/>
          ) : site === "ordenes" ? (
            <TableOrders />
          ) : site === "notificaciones" ? (
            <TableNotis />
          ) : site === "actualizarUser" ? (
            <TableSearchUser />
          ) : site === "profile" ? (
            <MyUser />
          ) : site === "clientes" ? (
            <TableClient />
          ) : site ===  "productos" ?(
            <TableProducts/>
          ) : site ===  "productogrupos" ?(
            <TableProductsGroups/> 
          ) : site ===  "emiters" ?(
            <TableEmiter/>
          ): site ===  "poliza" ?(
            <TableHopper/>
          ) : (
            <></>
          )
          
        ) 
        : site === "compras" ? (
          <TableOrders />
        )
        : site === "notificaciones" ? (
          <TableNotis />
        ) : site === "actualizarUser" ? (
          <TableSearchUser />
        ) : site === "profile" ? (
          <MyUser />
        ) : (
          <></>
        )}
      </DashboardContent>
    </DashboardContainer>
  );
};
