import regexForForms from "../../../../utils/regex-forms";
import isLegalAge from "../../../../utils/is-legal-age";

export default function Validate(values) {
  let errors = {};
  const {
    textRegex,
    addressRegex,
    documentOrPassportRegex,
    emailRegex,
    phoneNumberRegex,
  } = regexForForms();
  if (!values.name) {
    errors.name = "Ingrese el nombre";
  } else if (!textRegex.test(values.name)) {
    errors.name = "Nombre inválido";
  }
  if (!values.lastName) {
    errors.lastName = "Ingrese el apellido";
  } else if (!textRegex.test(values.lastName)) {
    errors.lastName = "Apellido inválido";
  }
  if (!values.location) {
    errors.location = "Ingrese la localidad";
  } else if (!textRegex.test(values.location)) {
    errors.location = "Localidad inválida";
  }
  if (!values.address) {
    errors.address = "Ingrese una dirección";
  } else if (!addressRegex.test(values.address)) {
    errors.address = "Dirección inválida";
  }
  if (!values.document) {
    errors.document = "Ingrese el DNI o Pasaporte";
  } else if (!documentOrPassportRegex.test(values.document)) {
    errors.document = "DNI o Pasaporte inválido";
  }
  if (!values.birthdate) {
    errors.birthdate = "Fecha de nacimiento es req.";
  } else if (!isLegalAge(values.birthdate)) {
    errors.birthdate = "Debes ser mayor de edad";
  }
  if (!values.sex) {
    errors.sex = "Ingrese el sexo";
  }
  if (!values.province) {
    errors.province = "Ingrese la provincia";
  }
  if (!values.covert) {
    errors.covert = "Ingrese cobertura";
  }
  if (!values.email) {
    errors.email = "Ingrese el email";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Email inválido";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Ingrese el número de teléfono";
  } else if (!phoneNumberRegex.test(values.phoneNumber)) {
    errors.phoneNumber = "Número inválido";
  }
  return errors;
}
