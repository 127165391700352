import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/UseUser";
import Form from "../../components/FormContainer/FormContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import StyledText from "../../components/StyledText/StyledText";
import Input from "../../components/Input/Input";
import onChange from "../../utils/on-change-input";
import Validate from "./loginUtils/validate";
import initSession from "./loginUtils/init-session";
import togglePassword from "../../utils/toggle-password";

export default function Login() {
  const navigate = useNavigate();
  const { login, isLoggedIn } = useUser();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setErrors(Validate(values));
  }, [values]);

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  return (
    <Form principalText>
      <div className="form-container w-100 w-60-l">
        <StyledText className="form-title" fontClasses="f4 f3-l">
          <FontAwesomeIcon className="img mr2" icon={faRightToBracket} />
          Inicio de sesión
        </StyledText>
        <div className="input-couple">
          <Input
            placeholder={"E-mail *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            type={"email"}
            name={"email"}
            validate={Validate}
          />
        </div>
        <div className="input-couple">
          <Input
            placeholder={"Contraseña *"}
            onChange={onChange}
            onClick={() => togglePassword({ setShowPassword, showPassword })}
            showPassword={showPassword}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            type={showPassword ? "text" : "password"}
            name={"password"}
            validate={Validate}
          />
        </div>
        <div className="flex flex-column items-center">
          {showErrors && Object.keys(errors).length ? (
            <p className="red tc">Por favor completa los campos correctamente</p>
          ) : (
            <></>
          )}
          <button
            className="button main-button w-50"
            onClick={(e) =>
              initSession({ e, values, errors, setShowErrors, login })
            }
          >
            <b>Acceder</b>
          </button>
          <span className="pt2 tc">
            <button
              href="#"
              onClick={() => navigate("/recuperar-contraseña")}
              className="button button-nohover"
            >
              Olvidaste tu contraseña?
            </button>
          </span>
          <span className="tc">
            ¿No tenés cuenta?{" "}
            <button
              className="button button-nohover"
              onClick={() => navigate("/registro")}
            >
              Crear Usuario
            </button>
          </span>
        </div>
      </div>
    </Form>
  );
}
