import { HttpClientCustomService } from "./httpclientcustom.service";

export default class hopperService {
    httpClientBase;

    constructor (
        httpClient,
    ) {
        this.httpClientBase = new HttpClientCustomService(
            httpClient,
        )
    }
    
    getHopperByUserId(page, token) {
        return this.httpClientBase
        .get(`/hopper/${page}`,token);
    }

    getHopperByEmiterId(emiterId, page, token) {
        return this.httpClientBase
        .get(`/hopper/emiter/${emiterId}?page=${page}`,token);
    }

    getHopperByInput(input, page, token) {
        return this.httpClientBase
        .get(`/hopper?input=${input}&page=${page}`,token);
    }

    putHopper(data, token) {
        return this.httpClientBase
        .put(`/hopper`, data , token);
    }

    putHopperBySource(data, token) {
        return this.httpClientBase
        .put(`/hopper/source`, data , token);
    }

    postHopper(orderId, source, token) {
        return this.httpClientBase
        .post(`/hopper`, {data:{orderId, source}} , token);
    }
}