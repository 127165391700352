import useUser from "../../../hooks/UseUser";
import CreateOrderButton from "../../../components/CreateOrderButton/CreateOrderButton";
import MPButton from "../../../components/MPButton/MPButton";
import CopyMPLink from "../../../components/CopyMPLink/CopyMPLink";
import StyledText from "../../../components/StyledText/StyledText";
import SummaryCard from "../../../components/ClientSummary/SummaryCard";
import ClientSummary from "../../../components/ClientSummary/ClientSummary";
import backInstance from "./formUtils/back-instance";
import trimValues from "../../../utils/trim-values";
import createPayMPT from "./formUtils/create-pay";
import createOrderMPT from "./formUtils/create-order";
import copyMPLinkMPT from "./formUtils/copy-mp-link";
import BackButton from "../../../components/BackButton/BackButton";
import { userIsPas } from "../../../utils/user-is-type";
import { Fragment } from "react";

export default function MPTSummary({ values, setInstance, instance }) {
  const { user } = useUser();
  const trimmedValues = trimValues(values);
  const {
    name,
    lastName,
    document,
    birthdate,
    sex,
    province,
    covert,
    destiny,
    from,
    to,
    address,
    email,
    phoneNumber,
    location,
  } = trimmedValues;
  const isPas = userIsPas(user);

  return (
    <Fragment>
      <ClientSummary>
        <SummaryCard>
          <StyledText className="dark-text f-medium" fontClasses="f5 f4-m f3-l">
            Medical Plus Turismo
          </StyledText>
          <hr />
          <div className="flex flex-column f6 f5-l">
            <p>{covert.description}</p>
            <p>
              {name} {lastName}
            </p>
            <p>
              {document}, {birthdate}
            </p>
            <p>
              {email}, {phoneNumber}
            </p>
            <p>{sex}</p>
            <p>
              {province}, {location}
            </p>
            <p>{address}</p>
            <div
              style={{ borderBottom: "1px solid gray" }}
              className="mv2"
            ></div>
            <p>
              <b>Destino: </b>
              {destiny}
            </p>
            <p>
              <b>Desde: </b>
              {from}, <b>Hasta: </b>
              {to}
            </p>
            <p
              style={{
                color: "var(--color-first-medium",
                textDecoration: "underline",
              }}
            >
              * La contratación mínima son 6 meses
            </p>
          </div>
        </SummaryCard>
      </ClientSummary>
      <div
        className={`w-90 w-75-l mt3 flex flex-column flex-row-l center justify-between`}
      >
        <BackButton
          backInstance={backInstance}
          setInstance={setInstance}
          instance={instance}
        />
        <div className="flex flex-column flex-row-l">
          {isPas ? (
            <>
              <CreateOrderButton
                values={trimmedValues}
                handleCreateOrder={createOrderMPT}
              />
              <CopyMPLink values={trimmedValues} handleCopy={copyMPLinkMPT} />
            </>
          ) : (
            <MPButton values={trimmedValues} handleCreatePay={createPayMPT} />
          )}
        </div>
      </div>
    </Fragment>
  );
}
