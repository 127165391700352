import { useEffect, useState } from "react";
import onChange from "../../../utils/on-change-input";
import Validate from "./formUtils/Validate";
import nextInstance from "./formUtils/next-instance";
import MPTBasicInfo from "./formInstances/MPTBasicInfo";
import icon from "../../../components/SectionProducts/imgs/c-icono_pasajero.png";
import Form from "../../../components/FormContainer/FormContainer";
import MPTSummary from "./MPTSummary";

export default function MPTForm() {
  const [values, setValues] = useState({
    name: "",
    lastName: "",
    document: "",
    birthdate: "",
    sex: "",
    province: "",
    covert: {},
    destiny: "",
    from: "",
    to: "",
    address: "",
    email: "",
    phoneNumber: "",
    location: "",
    type: "mpt",
  });
  const [instance, setInstance] = useState(1);
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    setErrors(Validate(values));
  }, [values]);

  return (
    <Form principalText>
      {instance === 1 && (
        <div className="img-container w-100 w-30-l tc pa3">
          <img src={icon} alt="bolso-protegido" />
          <p className="f3">Medical Plus Turismo</p>
        </div>
      )}
      <div
        className={`${
          instance === 2 ? "summary-container" : "form-container"
        } w-100`}
      >
        {instance === 1 && (
          <MPTBasicInfo
            values={values}
            setShowErrors={setShowErrors}
            setValues={setValues}
            errors={errors}
            onChange={onChange}
            setErrors={setErrors}
            showErrors={showErrors}
            Validate={Validate}
            nextInstance={nextInstance}
            instance={instance}
            setInstance={setInstance}
          />
        )}
        {instance === 2 && (
          <MPTSummary
            values={values}
            instance={instance}
            setInstance={setInstance}
          />
        )}
      </div>
    </Form>
  );
}
