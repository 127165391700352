import { Modal } from "../Modal/Modal";

export default function ModalUploadPolicies({
  handleClose,
  handleConfirm,
  selectedOrder,
}) {
  const { nr_cert, name, last_name, type, date_emit } = selectedOrder;
  const formattedProductType =
    type === "mpt" ? "MedicalPlusTurismo" : "BolsoProtegido";
  return (
    <Modal handleClose={handleClose} handleConfirm={handleConfirm} cruzButton>
      <p className="f4 b mb4 ws-normal">
        ¿Estas seguro de cargar la póliza a ésta orden?
      </p>
      <p style={{ color: "var(--color-first-dark" }}>Orden seleccionada:</p>
      <div className="w-100 w-90-m mw6-m w-75-l center br4 pa2 order-data">
        <p>
          <b>ID Orden:</b> {nr_cert}
        </p>
        <p>
          <b>Fecha de emisión:</b> {date_emit}
        </p>
        <p>
          <b>Nombre y Apellido:</b> {name} {last_name}
        </p>
        <p>
          <b>Producto:</b> {formattedProductType}
        </p>
      </div>
      <style jsx>{`
        .order-data {
          border-bottom: 4px solid var(--color-first-medium);
          background-color: #d7dcff;
        }
      `}</style>
    </Modal>
  );
}
