import { Icon } from "@iconify/react";
import SearchInput from "./SearchbarInput";

export const SearchbarTable = ({
  setInput,
  handleSubmitSearch,
  input,
}) => {
  const handleChangeSearch = (e) => {
    setInput(e.target.value);
  };
  return (
    <div className="flex">
      <SearchInput handleChangeSearch={handleChangeSearch} />
      <button
        className="button main-button br-none"
        onClick={() => handleSubmitSearch(input)}
      >
        <Icon icon="material-symbols:search" />
      </button>
    </div>
  );
};
