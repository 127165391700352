import React, { useEffect, useState } from "react";
import useUser from "../../../../hooks/UseUser";
import LoaderSpinner from "../../../LoaderSpinner/LoaderSpinner";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Modal } from "../../../Modal/Modal";
import { ModalDeleteProductsGroups } from "./ModalDeleteProductsGroups";
import { ModalCreateProductsGroups } from "./ModalCreateProductsGroups";
import productService from "../../../../services/product.service";

export const TableProductsGroups = () => {
  const { jwt, user } = useUser();
  const [productsGroups, setProductsGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const productServices = new productService()
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [data, setData] = useState({
    description:"",
  })

  const handleClose = () =>{ 
    setShowModalCreate(false)
    setShowModalDelete(false)
    getProductsGroups()
  }

  const getProductsGroups = () => {
    productServices.getProductsGroups(page, jwt).subscribe((response) => {
      const newData = response?.data || [];
      setProductsGroups((prevData) => {
        const uniqueData = newData.filter((newItem) =>
          prevData.every((existingItem) => existingItem.id !== newItem.id)
        );
        return prevData.concat(uniqueData);
      });
  
      setLoading(false);
    });
  };

  const handleOpenModalDelete = (id) => {
    setData({
        Id: id
    })
    setShowModalDelete(true)
  }

  const handleOpenModalCreate = () => {
    setData({
      description:""
    })
    setShowModalCreate(true)
  };

  const handleConfirmDelete = () => {
    productServices.deleteProductsGroups(data.Id, jwt).subscribe(s => {
      setProductsGroups((productsGroups) => productsGroups.filter((p) => p.id !== data.Id));
        setLoading(false);
      });
  }

  const handleConfirmCreate = () => {
    productServices.postProductsGroups(data, jwt).subscribe(()=>{
      setPage(1)
      productsGroups.length = 0;
      getProductsGroups()
    })
  }

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name] : e.target.value
    })
  }

  useEffect(() => {
    setLoading(true);
    getProductsGroups()
  }, [jwt, page]);

  return (
  <>
    {
    showModalCreate && 
          <Modal handleClose={handleClose} handleConfirm={handleConfirmCreate} cruzButton>
            <ModalCreateProductsGroups title={"Crear grupo"} data={data}  handleChange={handleChange}/>
          </Modal>
    }
    {
    showModalDelete && 
          <Modal handleClose={handleClose} handleConfirm={handleConfirmDelete} cruzButton>
            <ModalDeleteProductsGroups data={data.Id}/>
          </Modal>
    }
    <div className="w-100 h-100 pre" id="InfiniteScroll" style={{ overflowX: "auto" }}>
      <InfiniteScroll 
      dataLength={productsGroups.length} 
      next={() => {setPage(prev=>prev+1)}}
      hasMore={true}
      scrollableTarget="InfiniteScroll"
      >
        <button className="button success-button br-none mh2" onClick={()=>handleOpenModalCreate()}>
          <b>Crear grupo</b>
        </button>
      <table>
        <thead>
          <tr>
            <th scope="col">Descripción</th>
            <th scope="col">Habilitado</th>
          </tr>
        </thead>
        {productsGroups.length ? (
          <tbody>
            {productsGroups.map((e, i) => (
              <tr key={i}>
                <td>{e?.description}</td>
                <td onClick={()=>handleOpenModalDelete(e?.id)}><button className={ "button delete-button br-none mh2"}>{"Eliminar"}</button></td>
              </tr>
            ))}
          </tbody>
        ) : (
          <></>
        )}
      </table>
      </InfiniteScroll>
       {loading && <LoaderSpinner />}
    </div>
  </>
  );
};
