import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import {
  faBell,
  faArrowRightFromBracket,
  faPenToSquare,
  faCartShopping,
  faChartSimple,
  faBriefcase,
  faFileArrowUp,
  faUser,
  faList
} from "@fortawesome/free-solid-svg-icons";
import productService from "../../../services/product.service";
import useUser from "../../../hooks/UseUser";

export const SideBarAdmin = ({ logout, updateSite }) => {
  const [selectedArea, setSelectedArea] = useState("profile");
  const selectItem = (valor) => {
    setSelectedArea(valor);
  };
  const [notis, setNotis] = useState(0);
  const productServices = new productService()
  const { jwt } = useUser();
  useEffect(() => {
    productServices
      .getCountProductNotisAdmin(jwt)
      .subscribe(({data})=>{
        setNotis(data.total_records);
      })

  }, []);
  return (
    <>
      <ul>
        <p className="centerP text">Listas</p>
        <li
          onClick={() => {
            updateSite("actualizarUser");
            selectItem("actualizarUser");
          }}
          className={`${
            selectedArea === "actualizarUser" ? "selected " : ""
          }flex`}
        >
          <div className="w-100">
            <FontAwesomeIcon
              icon={faPenToSquare}
              className={selectedArea === "actualizarUser" ? "pl1" : ""}
            />
            <label className="text">Usuarios</label>
          </div>
        </li>
        <li
          onClick={() => {
            updateSite("ordenes");
            selectItem("ordenes");
          }}
          className={`${selectedArea === "ordenes" ? "selected " : ""}flex`}
        >
          <div className="w-100">
            <FontAwesomeIcon
              icon={faCartShopping}
              className={selectedArea === "ordenes" ? "pl1" : ""}
            />
            <label className="text">Órdenes</label>
          </div>
        </li>
        <li
          onClick={() => {
            updateSite("productos");
            selectItem("productos");
          }}
          className={`${selectedArea === "productos" ? "selected " : ""}flex`}
        >
          <div className="w-100">
            <FontAwesomeIcon
              icon={faBriefcase}
              className={selectedArea === "productos" ? "pl1" : ""}
            />
            <label className="text">Productos</label>
          </div>
        </li>
        <li
          onClick={() => {
            updateSite("productogrupos");
            selectItem("productogrupos");
          }}
          className={`${selectedArea === "productogrupos" ? "selected " : ""}flex`}
        >
          <div className="w-100">
            <FontAwesomeIcon
              icon={faList}
              className={selectedArea === "productogrupos" ? "pl1" : ""}
            />
            <label className="text">Grupo productos</label>
          </div>
        </li>
        <li
          onClick={() => {
            updateSite("emiters");
            selectItem("emiters");
          }}
          className={`${selectedArea === "emiters" ? "selected " : ""}flex`}
        >
          <div className="w-100">
            <FontAwesomeIcon
              icon={faUser}
              className={selectedArea === "emiters" ? "pl1" : ""}
            />
            <label className="text">Emisores</label>
          </div>
        </li>
        <li
          onClick={() => {
            updateSite("poliza");
            selectItem("poliza");
          }}
          className={`${selectedArea === "poliza" ? "selected " : ""}flex`}
        >
          <div className="w-100">
            <FontAwesomeIcon
              icon={faFileArrowUp}
              className={selectedArea === "poliza" ? "pl1" : ""}
            />
            <label className="text">Emitir polizas</label>
          </div>
        </li>
        <p className="centerP text">Útil</p>
        <li
          onClick={() => {
            updateSite("notificaciones");
            selectItem("notificaciones");
          }}
          className={`${
            selectedArea === "notificaciones" ? "selected " : ""
          }flex relative`}
        >
          {notis ? (
            <div
              style={{
                backgroundColor: "#dc3545",
                height: "12px",
                minWidth: 10,
                position: "absolute",
                borderRadius: "100%",
                left: "12px",
                fontSize: 10,
                top: "4px",
                textAlign: "center",
              }}
            >
              {notis < 10 ? notis : "+10"}
            </div>
          ) : (
            <></>
          )}
          <div className="w-100">
            <FontAwesomeIcon
              icon={faBell}
              className={selectedArea === "notificaciones" ? "pl1" : ""}
            />
            <label className="text">Notificaciones</label>
          </div>
        </li>
        <li
          onClick={() => {
            updateSite("panel");
            selectItem("panel");
          }}
          className={`${selectedArea === "panel" ? "selected " : ""}flex`}
        >
          <div className="w-100">
            <FontAwesomeIcon
              icon={faChartSimple}
              className={selectedArea === "panel" ? "pl1" : ""}
            />
            <label className="text">Estadísticas</label>
          </div>
        </li>
        <p className="centerP text">Usuario</p>
        <li
          onClick={() => {
            updateSite("profile");
            selectItem("profile");
          }}
          className={`${selectedArea === "profile" ? "selected " : ""}flex`}
        >
          <div className="w-100">
            <FontAwesomeIcon
              icon={faCircleUser}
              className={selectedArea === "profile" ? "pl1" : ""}
            />
            <label className="text">Perfil</label>
          </div>
        </li>
        <li onClick={logout} className="flex-l dn logout-option">
          <FontAwesomeIcon icon={faArrowRightFromBracket} />
          <div>Cerrar Sesión</div>
        </li>
      </ul>
    </>
  );
};
