import React, { useContext, useEffect, useState } from "react";
import { RecoveryContext } from "../Context/recoveryContext";
import { toast } from "react-toastify";
import Form from "../../components/FormContainer/FormContainer";
import StyledText from "../../components/StyledText/StyledText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Input/Input";
import Validate from "../../pages/Login/loginUtils/validate";
import { useNavigate } from "react-router";
import userService from "../../services/user.service";
import onChange from "../../utils/on-change-input";

export default function OTP() {
  const service = new userService();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: ""
  });
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const { setEmail, setPage, setOTP } = useContext(RecoveryContext);
  
  useEffect(() => {
      setErrors(Validate(values));
    }, [values]);

  const sendEmail = async () => {
    try {
      service.getMail(values.email).subscribe(s => {
        if (s.data) {
          const OTP = Math.floor(Math.random() * 9000 + 1000);
          setOTP(OTP);
          setEmail(values.email);
          const mailData = {
            subject: `Recupera tu contraseña`,
            recipient_email: values.email,
            inner_mail: `<!DOCTYPE html><html lang="en" ><head><meta charset="UTF-8"><title>Recuperá tu contraseña</title></head><body><!-- partial:index.partial.html --><div style="font-family: Helvetica,Arial,sans-serif;min-width:1000px;overflow:auto;line-height:2"><div style="margin:50px auto;width:70%;padding:20px 0"><div style="border-bottom:1px solid #eee"><a href="" style="font-size:1.4em;color: #00466a;text-decoration:none;font-weight:600">RUS</a></div><p style="font-size:1.1em">Hola,</p><p>ingresá este código de validación para cambiar tu contraseña! El código vence en 5 minutos</p><h2 style="background: #00466a;margin: 0 auto;width: max-content;padding: 0 10px;color: #fff;border-radius: 4px;">${OTP}</h2><p style="font-size:0.9em;">Saludos!</p><hr style="border:none;border-top:1px solid #eee" /><div style="float:right;padding:8px 0;color:#aaa;font-size:0.8em;line-height:1;font-weight:300"><p>Equipo de <a style="text-decoration: none" href="https://rus.com.ar">RUS</a></p></div></div></div><!-- partial --></body></html>`,
            attachFile: false,
          };
  
          service.postSendEmail(mailData).subscribe();
          setPage("otp");
        } else {
          toast.error(
            "Ocurrió un error al procesar los datos, intenta nuevamente más tarde",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      });
    } catch (e) {
      toast.error("El correo electrónico ingresado no está registrado!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Form principalText>
      <div className="form-container w-100 w-60-l">
        <StyledText className="form-title" fontClasses="f4 f3-l">
          <FontAwesomeIcon className="img mr2" icon={faUnlock} />
          Recuperar contraseña
        </StyledText>
        <div className="input-couple">
          <Input
            placeholder={"E-mail *"}
            onChange={onChange}
            values={values.email}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            type={"email"}
            name={"email"}
            validate={Validate}
          />
        </div>
        <div className="flex flex-column items-center">
          <button
            className="button main-button w-50"
            onClick={sendEmail}
          >
            <b>Recupera tu contraseña</b>
          </button>
          <span className="tc">
            ¿No tenés cuenta?{" "}
            <button
              className="button button-nohover"
              onClick={() => navigate("/registro")}
            >
              Crear Usuario
            </button>
          </span>
        </div>
      </div>
    </Form>
    </>


  );
}
