import isLegalAge from "../../../utils/is-legal-age";
import regexForForms from "../../../utils/regex-forms";

export default function Validate(values) {
  let errors = {};
  const { textRegex, emailRegex, passwordRegex } = regexForForms();

  if (!values.name) {
    errors.name = "Ingrese el nombre";
  } else if (!textRegex.test(values.name)) {
    errors.name = "Nombre inválido";
  }
  if (!values.lastName) {
    errors.lastName = "→ Por favor ingrese un valor";
  } else if (!textRegex.test(values.lastName)) {
    errors.lastName = "Nombre inválido";
  }
  if (!values.email) {
    errors.email = "→ Ingrese un email";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Email inválido";
  }
  if (!values.password) {
    errors.password = "→ Ingrese una contraseña";
  } else if (!passwordRegex.test(values.password)) {
    errors.password =
      "→ 8 a 15 caracteres - Al menos una letra mayúscula - Al menos un dígito numérico - Sin espacios - Al menos un carácter especial.";
  }
  if (!values.date) {
    errors.date = "Fecha de nacimiento es req.";
  } else if (!isLegalAge(values.date)) {
    errors.date = "Debes ser mayor de edad";
  }
  return errors;
}
