import React, { useState } from "react";
import OTP from "../../components/OTP/OTP";
import { RecoveryContext } from "../../components/Context/recoveryContext";
import OTPinput from "../../components/OTPInput/OTPInput";
import Reset from "../../components/Reset/Reset";
import Recovered from "../../components/Recovered/Recovered";

export default function OTPComps() {
  const [page, setPage] = useState("recover");
  const [email, setEmail] = useState();
  const [otp, setOTP] = useState();
  const [formData] = useState();
  function OTPComponents() {
    if (page === "recover") return <OTP />;
    if (page === "otp") return <OTPinput />;
    if (page === "reset") return <Reset />;
    return <Recovered />;
  }
  return (
    <RecoveryContext.Provider
      value={{ page, setPage, otp, setOTP, setEmail, email, formData }}
    >
      <OTPComponents />
    </RecoveryContext.Provider>
  );
}
