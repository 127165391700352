import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import onClickCheckbox from "../../utils/on-click-checkbox-input";

export default function Input({
  placeholder,
  type,
  name,
  onChange,
  onClick,
  value,
  values,
  setValues,
  errors,
  setErrors,
  showErrors,
  showPassword,
  classes,
  styles,
  options,
  validate,
  instance,
  rows,
  cols,
}) {
  return (
    <>
      {type === "textarea" ? (
        <div
          className={`flex input-container w-100 ${classes ? classes : ""}`}
          style={styles}
        >
          <textarea
            name={name}
            onChange={(e) => {
              onChange({
                e,
                values,
                setValues,
                setErrors,
                validate,
                instance,
              });
            }}
            placeholder={placeholder}
            value={values[name]}
            rows={rows}
            cols={cols}
          />
        </div>
      ) : type === "select" ? (
        <div
          style={styles}
          className={`select-container w-100 ${classes ? classes : ''} ${showErrors && errors[name] ? 'input-error' : ''}`}
        >
          <label>{placeholder}</label>
          <select
            name={name}
            onChange={(e) =>
              onChange({ e, values, setValues, setErrors, validate })
            }
          >
            <option value="" disabled selected hidden>
              Seleccione
            </option>
            {options.map((opt, i) => {
              return (
                <option key={i} value={opt?.value ? opt.value : opt.id ? JSON.stringify(opt) : opt}>
                  {opt?.name ? opt.name : opt.description ? opt.description : opt}
                </option>
              );
            })}
          </select>
        </div>
      ) : type === "checkbox" ? (
        <div
          className={`flex checkbox-container ${classes ? classes : ''}`}
          style={styles}
        >
          <input
            type={type}
            name={name}
            onClick={(e) => {
              onClickCheckbox({ e, values, setValues });
            }}
            placeholder={placeholder}
            value={value}
          />
          <label>{placeholder}</label>
        </div>
      ) : (
        <div
          className={`flex input-container w-100 ${classes ? classes : ""} ${showErrors && errors[name] ? 'input-error' : ''}`}
          style={styles}
        >
          <label>{placeholder}</label>
          <input
            type={type}
            name={name}
            onChange={(e) => {
              onChange({ e, values, setValues, setErrors, validate });
            }}
            value={values[name]}
          />
          {name === "password" && (
            <span onClick={onClick}>
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </span>
          )}
        </div>
      )}
    </>
  );
}
