import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { URLSERVER } from "../../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import useUser from "../../../../hooks/UseUser";
import { userIsAdmin } from "../../../../utils/user-is-type";
import LoaderSpinner from "../../../LoaderSpinner/LoaderSpinner";
import { SearchbarTable } from "../../Searchbar/SearchbarTable";
import NoResultsText from "../../../NoResultsText/NoResultsText";
import FileInput from "../../../FileInput/FileInput";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalUploadPolicies from "../../../ModalUploadPolicies/ModalUploadPolicies";
import productService from "../../../../services/product.service";
import hopperService from "../../../../services/hopper.service";
import userService from "../../../../services/user.service";

export const TableHopper = () => {
  const userServices = new userService()
  const hopperServices = new hopperService()
  const productsService = new productService()
  const { user, jwt } = useUser();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileStatus, setFileStatus] = useState(0);
  const [page, setPage] = useState(1);
  const [input, setInput] = useState("");
  const [pageSearch, setPageSearch] = useState(1);
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    order: null,
  });
  const [showModalUploadPolicies, setShowModalUploadPolicies] = useState(false);
  const isAdmin = userIsAdmin(user);
  const [data, setData] = useState()

  const handleChangeSearch = useCallback((e) => {
    setInput(e.target.value);
    setOrders([]);
    if (e.target.value === "") {
      setPage(1);
    } else {
      setPage(0);
      setPageSearch(1);
    }
  }, []);

  const handleSubmitSearch = useCallback((input) => {
    if (input.trim().length) {
      hopperServices.getHopperByInput(input.trim(), pageSearch, jwt).subscribe((s) => {
        setOrders(s.data)
      });
    };
  }, [jwt, pageSearch]);

  const fetchAllOrders = useCallback(() => {
    setLoading(true)
    hopperServices.getHopperByUserId(page, jwt).subscribe((s) => {
      setLoading(false);
      setOrders(orders.concat(s?.data))
    })
  }, [jwt, setLoading, setOrders, page])

  const handleFileChange = (event, order) => {
    const file = event.target.files[0];
    setFileStatus(1);
    setSelectedFile({ file, order });
  };

  const handleOpenModal = () => {
    setShowModalUploadPolicies(true);
  };

  const handleCloseModal = () => {
    setSelectedFile({ file: null, order: null });
    setShowModalUploadPolicies(false);
  };

  const setStatus = (active) => {
    setLoading(true);
    setData(active)
    userServices.putEmiterActiveByUserId({ userId: user.id, active }, jwt).subscribe(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (input.trim().length) {
      handleSubmitSearch(input)
    }
  }, [handleChangeSearch, input, handleSubmitSearch]);


  useEffect(() => {
    userServices.getEmiterByUserId(user.id, jwt).subscribe((s) => {
      setData({
        emiterId: s.data[0].emiterId,
        userId: user.id,
        active: s.data[0].active
      })
    });
  }, [loading])

  const handleUpload = (order) => {
    if (selectedFile) {
      setFileStatus(2);
      const formData = new FormData();
      formData.append("poliza", selectedFile.file);
      productsService.loadPdf(formData, order.nr_cert, jwt).subscribe((s) => {
        if (s.response.data) {
          setFileStatus(3);
          setTimeout(() => {
            setSelectedFile({ file: null, order: null });
          }, 1000);
          hopperServices.getHopperByUserId(1, jwt).subscribe((response) => {
            setPage(2)
            setOrders(response?.data);
          });
          hopperServices.putHopper({ data: { orderId: order.id, emited: 1 } }, jwt).subscribe()
        } else {
          setFileStatus(0);
          setTimeout(() => {
            setSelectedFile({ file: null, order: null });
          }, 1000);
        }
      })
    }
  };

  useEffect(() => {
    if (isAdmin) {
      if (!input?.trim()?.length) fetchAllOrders();
    }
  }, [page, input]);

  return (
    <div className="w-100 h-100 pre" id="InfiniteScroll" style={{ overflowX: "auto" }}>
      <div className="flex justify-between">
        <div className="flex justify-start pv2">
          {isAdmin && (
            <Fragment>
              <SearchbarTable
                setInput={setInput}
                handleSubmitSearch={handleSubmitSearch}
                input={input}
              />
            </Fragment>
          )}
        </div>
        <div>
          {data?.active === 1 ?
            <button className="mv1 mh1 button success-button br-none" onClick={() => setStatus(0)}>
              <b>Estado activo</b>
            </button>
            :
            <button className="mv1 mh1 button delete-button br-none" onClick={() => setStatus(1)}>
              <b>Estado desactivo</b>
            </button>
          }
        </div>
      </div>
      <table style={{ overflowX: "auto" }}>
        <InfiniteScroll
          dataLength={orders?.length}
          next={() => {
            if (page) setPage((prev) => prev + 1);
            else setPageSearch((prev) => prev + 1);
          }}
          hasMore={true}
          scrollableTarget="InfiniteScroll"
        >
          <thead>
            <tr>
              <th scope="col">Producto</th>
              <th scope="col">Nombre y Apellido</th>
              <th scope="col">Telefono</th>
              <th scope="col">Certificado</th>
              <th scope="col">Monto</th>
              <th scope="col">Id de órden</th>
              <th scope="col">Estado</th>
              <th scope="col">Emisión</th>
              <th scope="col">Póliza</th>
            </tr>
          </thead>
          {orders?.flat()?.length ? (
            <tbody>
              {orders?.flat().map((e, i) => (
                <tr key={i}>
                  <td data-label="Producto">
                    {e?.type === "mpt"
                      ? "MedicalPlusTurismo"
                      : e?.type === "bp" && "BolsoProtegido"}
                  </td>
                  <td data-label="Nombre y Apellido">
                    {e?.name}, {e?.last_name}
                  </td>
                  <td data-label="Telefono">
                    {e?.phone_number ? (
                      <a
                        href={`https://wa.me/+549${e?.phone_number}`}
                        target="_blank"
                        className="flex justify-center items-center"
                        rel="noreferrer"
                      >
                        <Icon icon="ic:baseline-whatsapp" height="24" />
                        <p>{e.phone_number}</p>
                      </a>
                    ) : (
                      "Sin número"
                    )}
                  </td>
                  <td data-label="Certificado">
                    <a
                      target="_blank"
                      className="btn btn-success btn-sm"
                      href={`${URLSERVER}/${e?.pathcertificate}`}
                      rel="noreferrer"
                    >
                      Ver/enviar PDF
                    </a>
                  </td>
                  <td data-label="Monto">{e?.price}</td>
                  <td data-label="Id de órden">{e?.nr_cert}</td>
                  <td data-label="Estado">
                    {e?.active === 1 ? (
                      <button className="button">
                        <strong className="green">
                          Activa <FontAwesomeIcon icon={faCircleCheck} />
                        </strong>
                      </button>
                    ) : (
                      <strong className="red">
                        Inactiva <FontAwesomeIcon icon={faCircleXmark} />
                      </strong>
                    )}
                  </td>
                  <td data-label="Emisión">
                    {e?.emited === 1 ? (
                      <strong className="green">
                        Emitida <FontAwesomeIcon icon={faCircleCheck} />
                      </strong>
                    ) : (
                      <button className="button">
                        <strong className="red">
                          No emitida <FontAwesomeIcon icon={faClock} />
                        </strong>
                      </button>
                    )}
                  </td>
                  <td>
                    {!e?.poliza_name && isAdmin ? (
                      <FileInput
                        handleFileChange={handleFileChange}
                        handleOpenModal={handleOpenModal}
                        order={e}
                        selectedFile={selectedFile}
                        fileStatus={fileStatus}
                      />
                    ) : !e?.poliza_name ? (
                      <span>Sin póliza</span>
                    ) : (
                      <a
                        href={`${process.env.REACT_APP_API}/polizas/${e?.poliza_name}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Ver póliza
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <></>
          )}
        </InfiniteScroll>
      </table>
      {loading && <LoaderSpinner />}
      {!loading && !orders?.length && <NoResultsText />}
      {showModalUploadPolicies && selectedFile.order && (
        <ModalUploadPolicies
          handleConfirm={() => handleUpload(selectedFile.order)}
          handleClose={handleCloseModal}
          selectedOrder={selectedFile.order}
        />
      )}
    </div>
  );
};