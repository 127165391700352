import { useState } from "react";
import { Charts } from "../../components/DashboardComponents/Charts/Charts"
import { Featured } from "../../components/DashboardComponents/Featured/Featured"
import { Widget } from "../../components/DashboardComponents/Widget/widget"
import { useEffect } from "react";
import productService from "../../services/product.service";
import useUser from "../../hooks/UseUser";

export const Panel = () => {
    const [clients, setClients] = useState();
    const [orders, setOrders] = useState();
    const [amount, setAmount] = useState(0);
    const { jwt } = useUser();
    const productSrv = new productService()
  
    useEffect(() => {
      productSrv.getNumnerOfClient(jwt).subscribe(({data}) => {
        setClients(data)
      })
      productSrv.getNumberOfOrders(jwt).subscribe(({data}) => {
        setOrders(data.total_records)
      })
    }, []);

    return <>
    <div className="widgets flex-l">
      <Widget type="user" amount={clients} />
      <Widget type="order" amount={orders} />
      <Widget type="balance" amount={amount} />
    </div>
    <div className="charts flex-l">
      <Featured />
      <Charts />
    </div>
  </>
}