import React, { useEffect } from "react";
import { useState } from "react";
import useUser from "../../hooks/UseUser";
import Validate from "./functions/Validate";
import "./style.css";
import StyledText from "../../components/StyledText/StyledText";
import Input from "../../components/Input/Input";
import onChange from "../../utils/on-change-input";
import togglePassword from "../../utils/toggle-password";
import userService from "../../services/user.service.js";

export function MyUser() {
  const { jwt, user } = useUser();
  const [userData, setUserData] = useState({
    name: "",
    lastName: "",
    email: user?.email || "",
    document: "",
    phoneNumber: "",
    province: "",
    city: "",
    streetName: "",
    postalCode: "",
    password: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const service = new userService()

  useEffect(() => {
    setLoadingData(true);
    service.myPersonalData(jwt).subscribe((s) => {
      setUserData(s.data);
      setLoadingData(false);
    })
  }, [jwt]);

  const handleSubmit = (user) => {
    if (!loadingData) {
      let canUpdatePAS = true;

      if (user.phone_number !== "" && !/^[a-zA-Z0-9-]+$/.test(user.route)) canUpdatePAS = false;
      let errors = {};
      if (user.type === "pas") {
        if (!canUpdatePAS) {
          errors = {
            phone_number: "Campo requerido",
            route: "Campo requerido",
          };
        }
      }

      if (Object.keys(errors).length === 0) {
        service.updateUserInfo(user,jwt).subscribe()
      } else {
        setErrors(errors);
        setShowErrors(!showErrors);
      }
    }
  };

  return (
    <div className="form-container w-100 max-height-33rem">
      <StyledText className="form-title" fontClasses="f4 f3-l">
        Actualizar usuario
      </StyledText>
      <div className="input-couple">
        <Input
          placeholder={"Nombre *"}
          onChange={onChange}
          values={userData}
          setValues={setUserData}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          styles={{
            marginBottom: "2rem",
            marginRight: "0.5rem",
          }}
          type={"text"}
          name={"name"}
          validate={Validate}
        />
        <Input
          placeholder={"Apellido *"}
          onChange={onChange}
          values={userData}
          setValues={setUserData}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          styles={{
            marginBottom: "2rem",
          }}
          type={"text"}
          name={"lastName"}
          validate={Validate}
        />
      </div>
      <div className="input-couple">
        <Input
          placeholder={"Email *"}
          onChange={onChange}
          values={userData}
          setValues={setUserData}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          styles={{
            marginBottom: "2rem",
            marginRight: "0.5rem",
          }}
          type={"text"}
          name={"email"}
          validate={Validate}
        />
        {/* <Input
          placeholder={"DNI o Pasaporte*"}
          onChange={onChange}
          values={userData}
          setValues={setUserData}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          styles={{
            marginBottom: "2rem",
          }}
          type={"text"}
          name={"document"}
          validate={Validate}
        /> */}
      </div>
      <div className="input-couple">
        <Input
          placeholder={"Número de teléfono *"}
          onChange={onChange}
          values={userData}
          setValues={setUserData}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          styles={{
            marginBottom: "2rem",
            marginRight: "0.5rem",
          }}
          type={"number"}
          name={"phoneNumber"}
          validate={Validate}
        />
        <Input
          placeholder={"Provincia *"}
          onChange={onChange}
          values={userData}
          setValues={setUserData}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          styles={{
            marginBottom: "2rem",
          }}
          type={"text"}
          name={"province"}
          validate={Validate}
        />
      </div>
      {userData?.type === "client" && (
        <div className="input-couple">
          <Input
            placeholder={"Ciudad *"}
            onChange={onChange}
            values={userData}
            setValues={setUserData}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            styles={{
              marginBottom: "2rem",
              marginRight: "0.5rem",
            }}
            type={"text"}
            name={"city"}
            validate={Validate}
          />
          <Input
            placeholder={"Calle *"}
            onChange={onChange}
            values={userData}
            setValues={setUserData}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
 
            type={"text"}
            name={"streetName"}
            validate={Validate}
          />
          <Input
            placeholder={"Código postal *"}
            onChange={onChange}
            values={userData}
            setValues={setUserData}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            styles={{
              marginBottom: "2rem",
              marginRight: "0.5rem",
            }}
            type={"text"}
            name={"postalCode"}
            validate={Validate}
          />
        </div>
      )}
      {userData?.type === "pas" && (
        <div>
          <div className="input-couple">
            <Input
              placeholder={"Descripción *"}
              onChange={onChange}
              values={userData}
              setValues={setUserData}
              errors={errors}
              setErrors={setErrors}
              showErrors={showErrors}
              styles={{
                marginBottom: "2rem",
              }}
              type={"textarea"}
              name={"description"}
              validate={Validate}
              rows="5"
              cols="50"
            />
          </div>
        </div>
      )}
      <div className="flex justify-center items-center pt2 pb3">
        <button
          className="button main-button"
          onClick={() => handleSubmit(userData)}
        >
          <b>Actualizar</b>
        </button>
      </div>
    </div>
  );
}
