export default function SearchInput({ handleChangeSearch }) {
  return (
    <div className="search-input">
      <input className='w-100 h-100' type="text" onChange={handleChangeSearch} placeholder="Buscar" />
      <style jsx>{`
      .search-input input {
        border: 1px solid rgb(201, 201, 201)
      }
      `}</style>
    </div>
  );
}