export default function nextInstance({
  e,
  errors,
  setShowErrors,
  setInstance,
  instance,
}) {
  e.preventDefault();
  let canNextInstance = false;
  if (instance === 1) {
    canNextInstance = !(Object.keys(errors).length);
  }
  setShowErrors(!canNextInstance);
  if (canNextInstance) setInstance(instance + 1);
}
