import React, { useState } from "react";
import useUser from "../../hooks/UseUser";
import { Modal } from "../Modal/Modal";

export default function CopyMPLink({ values, handleCopy }) {
  const { user } = useUser();
  const [isClicked, setIsClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
    if (!isClicked) { 
      setIsClicked(true);
    }
  };

  const handleClose = () =>{ 
    setIsClicked(false);
    setShowModal(false)
  }
  
  const handleConfirmCreate = () => {
    handleCopy({ values, user });
  }

  return (
    <>
    <button
      className={`button mp-button flex justify-center items-center ${isClicked ? 'disabled' : ''}`}
      style={{ paddingBottom: 0, paddingTop: 0 }}
      onClick={handleClick}
      disabled={isClicked}
    >
      <b>Generar link MP</b>
      <span className="span-icon mp-icon flex ml1"></span>
    </button>
     {showModal && 
      <Modal handleClose={handleClose} handleConfirm={handleConfirmCreate} cruzButton>
            <h4>Seguro que desea generar link de pago?</h4>
      </Modal>
      }
    </>
  );
}
