import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import { UserContext } from "./components/Context/userContext";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { ProtectRoutePas } from "./ProtectRoutePas";
import OTPComps from "./pages/OTP/OTPComponents";
import BPForm from "./pages/Products/BolsoProtegido/BPForm";
import MPTForm from "./pages/Products/MedicalPlusTurismo/MPTForm";
import { ToastContainer } from "react-toastify";
import { ProtectRouteLogin } from './ProtectRouteLog'
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <UserContext>
      <div className="App">
        <ToastContainer />
        <BrowserRouter>
          <Navbar />
          <Routes>
            {/* Public home routes */}
            <Route path="/" element={
            <ProtectRouteLogin>
              
            </ProtectRouteLogin>} />
            <Route path="/ingresar" element={<Login />} />
            <Route path="/registro" element={<Register />} />

            {/* Public PAS Products routes */}
            <Route
              path="/producto/medical-plus-turismo"
              element={<MPTForm />}
            />
            <Route path="/producto/bolso-protegido" element={<ProtectRoutePas><BPForm /></ProtectRoutePas>} />
            <Route path="/recuperar-contraseña" element={<OTPComps />} />
            <Route
              path="/rus/panel"
              element={

                <ProtectRoutePas>
                  <Dashboard/>
                </ProtectRoutePas>
              }
            />
            <Route
              path="/clientes/panel"
              element={
                <ProtectRoutePas>
                  <Dashboard />
                </ProtectRoutePas>
              }
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </UserContext>
  );
}

export default App;
