import { toast } from "react-toastify";
import orderService from "../../../../services/order.service";
import userService from "../../../../services/user.service";

export default function copyMPLinkBP({ values, user }) {
  const service = new orderService();
  const userServices = new userService();

  service.postCreatePlan({ ...values, pas_id: "covery", usersId: user?.id || "not loggin" }).subscribe(({ response }) => {
    var enlace = response.data;
    var input = document.createElement("input");
    input.value = enlace;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    toast.success("El enlace se ha copiado al portapapeles: " + enlace, {
      position: "top",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    try {
      const mailData = {
        subject: `Link de pago`,
        recipient_email: values.email,
        inner_mail: `
              <div style="max-width: 600px; margin: auto; font-family: Arial, sans-serif; background-color: #ffffff; padding: 20px; border: 1px solid #dddddd;">
              <div style="text-align: center;">
              <img src="https://viajando.emititupoliza.com/static/media/logo.5f5ba2cc60e6f13a786d.png" alt="Rio Uruguay Seguros" style="max-width: 200px;">
              </div>
              </div>
              <div style="max-width: 600px; margin: auto; font-family: Arial, sans-serif; background-color: #ffffff; padding: 20px; border: 1px solid #dddddd;">
              <h1>Póliza de seguro</h1>
              <p>Rio Uruguay Seguros te acerca tu link de pago.</p>
              <a href="${enlace}">Pagá tu poliza de seguro</a>
              </div>
              `,
        attachFile: false,
      };
      userServices.postSendEmailMP(mailData).subscribe(() => window.location.href = "/");
    } catch (e) {
      toast.error("Ocurrió un error al enviar el mail!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  })
}
