import React, { useState } from "react";
import useUser from "../../../../hooks/UseUser";
import userService from "../../../../services/user.service";

export default function UserInfoRow({ e, fetchAllUsers}) {
  const { jwt, user } = useUser();
  const [select, setSelect] = useState(null);
  const serviceUser = new userService()
  const handleSelect = (id, element) => {
    if (element.target.value.length) {
      setSelect({
        ...select,
        type: element.target.value,
        id,
      });
    }
    return;
  };

  const sendChange =() => {
    if (select) {
      serviceUser.updateUser( select?.type, select?.id, jwt).subscribe(res=>{
        fetchAllUsers();
        setSelect(null)
      })
    }
    return;
  };

  return (
    <tr>
      <td>{e?.name}</td>
      <td>{e?.last_name}</td>
      <td>{e?.email}</td>
      <td>{e?.type}</td>
      <td className="flex">
        {e?.type !== "superadmin" ? (
          e?.type !== "admin" || user.type === "superadmin" ? (
            <>
              <select onChange={(element) => handleSelect(e.id_user, element)}>
                <option selected="true" disabled="disabled">
                  Seleccionar
                </option>
                {user.type === "superadmin" && e?.type === "admin" && (
                  <option value="2">Super Admin</option>
                )}
                {(e?.type !== "admin" || user.type === "superadmin") && (
                  <>
                    {e?.type !== "pas" && <option value="3">Vendedor</option>}
                    {e?.type !== "client" && (
                      <option value="4">Cliente</option>
                    )}
                    {user.type === "superadmin" && (
                      <option value="1">Admin</option>
                    )}
                  </>
                )}
              </select>
              <button
                className="button main-button"
                onClick={select?.type?.length && sendChange}
                disabled={!select?.type?.length}
              >
                Cambiar tipo
              </button>
            </>
          ) : (
            <p>No disponible</p>
          )
        ) : (
          <p>No disponible</p>
        )}
      </td>
    </tr>
  );
};
