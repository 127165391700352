import BackButton from "../BackButton/BackButton";

export const Modal = ({ children, handleClose, handleConfirm, cruzButton, setInstance, instance }) => {
  const performAction = () => {
    handleConfirm && handleConfirm();
    handleClose();
  };

  return (
    <div className="vh-100 w-100 fixed absolute--fill-ns top-0 left-0 z-9999 modal-background overflow-auto">
      <div className="w-75-m w-50-l center bg-white br4 pa2 mt6 overflow-auto">
        <div className="w-100 flex flex-column items-end tc">
          {cruzButton && <BackButton cruzIcon backInstance={handleClose} />}
          <div className="center w-100 ph1">{children}</div>
          <div className="w-100 mt4 flex flex-row-ns flex-wrap justify-around">
            <BackButton 
            setInstance={setInstance}
            instance={instance}
            text="Cancelar" 
            backInstance={handleClose} />
            <button className="button main-button w4" onClick={performAction}>
              <b>Aceptar</b>
            </button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .modal-background {
          background-color: #000000cc;
        }
      `}</style>
    </div>
  );
};

export default function ModalPortal({
  children,
  onClose,
  toggleModal,
  title,
  description,
  handleConfirm,
}) {
  return toggleModal ? (
    <Modal
      onClose={onClose}
      title={title}
      description={description}
      handleConfirm={handleConfirm}
    />
  ) : null;
}
