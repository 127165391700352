import React from 'react'
import useUser from './hooks/UseUser';
import {Navigate} from "react-router-dom"
import Home from './pages/Home/Home';
import Container from './components/Container/Container';
export const ProtectRouteLogin = () => {
    let {isLoggedIn, user} = useUser();
    if(isLoggedIn) { 
      return (
        user.type === "client" ?
        <Container>
          <div className="principal-text tc pv1 ph3">
            <h3 className="f3-l f4 b">Su usuario sera validado por un administrador</h3>
            <style jsx>{`
              .principal-text h3 {
                color: var(--color-first-medium);
              }
            `}</style>
        </div>
        </Container> 
        : 
          <Home />
      )
    }else return <Navigate to="/ingresar"/>
}