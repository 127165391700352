import React, { useEffect, useState } from "react";
import useUser from "../../../../hooks/UseUser";
import LoaderSpinner from "../../../LoaderSpinner/LoaderSpinner";
import InfiniteScroll from 'react-infinite-scroll-component';
import productService from "../../../../services/product.service";
import { Modal } from "../../../Modal/Modal";
import { ModalUpdateProduct } from "./ModalUpdate";
import { ModalDeleteProduct } from "./ModalDeleteProduct";

export const TableProducts = () => {
  const { jwt } = useUser();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const productSrv = new productService()
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [data,setData] = useState({
    description:"",
    price:"",
    sa:"",
    saDaily:"",
    productGroupId:0
  })

  const handleOpenModalDelete = (id) => {
    setData(id)
    setShowModalDelete(true)
  }

  const handleOpenModalUpdate = (e) => {
    setData(e)
    setShowModalUpdate(true)
  };

  const handleOpenModalCreate = () => {
    setData({
      description:"",
      price:"",
      sa:"",
      saDaily:"",
      productGroupId:0
    })
    setShowModalCreate(true)
  };    

  const handleClose = () =>{ 
    setShowModalUpdate(false)
    setShowModalCreate(false)
    setShowModalDelete(false)
  }
  

  const handleConfirmDelete = () => {
    productSrv.deleteProducts(data, jwt).subscribe(() => {
      setProducts((products) => products.filter((p) => p.id !== data));
    });
  }

  const handleConfirmCreate = () => {
    productSrv.postProducts(data, jwt).subscribe(()=>{
      setPage(1)
      products.length = 0;
      getProducts()
    })
  }

  const handleConfirmUpdate = () => {
    productSrv.updateProducts(data.id, data, jwt).subscribe(()=>{
      setPage(1)
      products.length = 0;
      getProducts()
    })
  }

  const handleSelect = (groupId) => {
    setData({
      ...data,
      productGroupId : groupId
    })
  }

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name] : e.target.value
    })
  }

  const getProducts = () => {
    productSrv.getProducts(page, jwt).subscribe((response) => {
      const newProducts = response?.data || [];
      setProducts((prevProducts) => {
        const uniqueProducts = newProducts.filter((newProduct) =>
          prevProducts.every((existingProduct) => existingProduct.id !== newProduct.id)
        );
        return prevProducts.concat(uniqueProducts);
      });
  
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getProducts()
  }, [jwt, page]);

  return (
    <>
    {showModalUpdate && 
          <Modal handleClose={handleClose} handleConfirm={handleConfirmUpdate} cruzButton>
            <ModalUpdateProduct title={"Actualizar producto"} data={data} handleChange={handleChange} handleSelect={handleSelect}/>
          </Modal>
          }
    {showModalCreate && 
          <Modal handleClose={handleClose} handleConfirm={handleConfirmCreate} cruzButton>
            <ModalUpdateProduct title={"Crear producto"} data={data} handleChange={handleChange} handleSelect={handleSelect}/>
          </Modal>
          }
    {showModalDelete && 
          <Modal handleClose={handleClose} handleConfirm={handleConfirmDelete} cruzButton>
            <ModalDeleteProduct/>
          </Modal>
          }
    <div className="w-100 h-100 pre" id="InfiniteScroll" style={{ overflowX: "auto" }}>
      <InfiniteScroll 
      dataLength={products.length} 
      next={() => {setPage(prev=>prev+1)}}
      hasMore={true}
      scrollableTarget="InfiniteScroll"
      >
        <button className="button success-button br-none mh2" onClick={()=>handleOpenModalCreate()}>
          <b>Crear producto</b>
        </button>
      <table>
        <thead>
          <tr>
            <th scope="col">Descripción</th>
            <th scope="col">Grupo</th>
            <th scope="col">Precio</th>
            <th scope="col">Suma asegurada</th>
            <th scope="col">Suma asegurada diaria</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        {products.length ? (
          <tbody>
            {products.map((e, i) => (
              <tr  className="dim pointer" key={i}>
                <td onClick={()=>handleOpenModalUpdate(e)}>{e?.description}</td>
                <td onClick={()=>handleOpenModalUpdate(e)}>{e?.descriptionGroup}</td>
                <td onClick={()=>handleOpenModalUpdate(e)}>{e?.price}</td>
                <td onClick={()=>handleOpenModalUpdate(e)}>{e?.sa}</td>
                <td onClick={()=>handleOpenModalUpdate(e)}>{e?.saDaily}</td>
                <td><button onClick={()=>handleOpenModalDelete(e.id)} className="button delete-button br-none mh2">Eliminar</button></td>
              </tr>
            ))}
          </tbody>
        ) : (
          <></>
        )}
      </table>
      </InfiniteScroll>
       {loading && <LoaderSpinner />}
    </div>
    </>
  );
};
