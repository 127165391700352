import "./ProductCard.css";

export default function ProductCard({ props }) {
  return (
    <div className="product-card">
      <div className="tc">
        <img src={props?.img} alt={props?.title} />
      </div>
      <p className="f6-m f6-l f7 tc ph2">{props?.title}</p>
    </div>
  );
}
