import Input from "../../../../components/Input/Input";
import StyledText from "../../../../components/StyledText/StyledText";
import { provinces, sex } from "../accessories";
import productServices from "../../../../services/product.service";
import useUser from "../../../../hooks/UseUser";
import { useEffect, useState } from "react";

export default function BPBasicInfo({
  values,
  setShowErrors,
  setValues,
  errors,
  onChange,
  setErrors,
  showErrors,
  Validate,
  nextInstance,
  instance,
  setInstance,
}) {
  const { jwt } = useUser();
  const [products, setProducts] = useState([]);
  const productService = new productServices();
  
  useEffect(() => {
    productService.getProductsByGroup(1, jwt).subscribe(s => {
      setProducts(s?.data);
    });
  },[jwt])

  return (
    <form>
      {/* DATOS DEL VEHICULO */}
      <div className="basic-info">
        <StyledText className="form-title" fontClasses="f4 f3-l">
          Datos del asegurado
        </StyledText>
        <div className="input-couple">
          <Input
            placeholder={"Nombre *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            styles={{
              marginRight: "0.5rem",
            }}
            type={"text"}
            name={"name"}
            validate={Validate}
            instance={instance}
          />
          <Input
            placeholder={"Apellido *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            type={"text"}
            name={"lastName"}
            validate={Validate}
            instance={instance}
          />
        </div>
        <div className="input-couple">
          <Input
            placeholder={"DNI *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            styles={{
              marginRight: "0.5rem",
            }}
            type={"number"}
            name={"document"}
            validate={Validate}
            instance={instance}
          />
          <Input
            placeholder={"Fecha de Nacimiento *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            type={"date"}
            name={"birthdate"}
            validate={Validate}
            instance={instance}
          />
        </div>
        <div className="input-couple">
          <Input
            placeholder={"Provincia *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            styles={{
              marginRight: "0.5rem",
            }}
            type={"select"}
            name={"province"}
            options={provinces}
            validate={Validate}
            instance={instance}
          />
          <Input
            placeholder={"Localidad *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            styles={{
              marginRight: "0.5rem",
            }}
            type={"text"}
            name={"location"}
            validate={Validate}
            instance={instance}
          />
          <Input
            placeholder={"Dirección *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            type={"text"}
            name={"address"}
            validate={Validate}
            instance={instance}
          />
        </div>
        <div className="input-couple">
          <Input
            placeholder={"Email *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            styles={{
              marginRight: "0.5rem",
            }}
            type={"text"}
            name={"email"}
            validate={Validate}
            instance={instance}
          />
          <Input
            placeholder={"Whatsapp *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            type={"number"}
            name={"phoneNumber"}
            validate={Validate}
            instance={instance}
          />
        </div>
        <div className="input-couple">
          <Input
            placeholder={"Sexo *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            styles={{
              marginRight: "0.5rem",
            }}
            type={"select"}
            name={"sex"}
            options={sex}
            validate={Validate}
            instance={instance}
          />
          <Input
            placeholder={"Cobertura *"}
            onChange={onChange}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            type={"select"}
            name={"covert"}
            options={products}
            validate={Validate}
            instance={instance}
          />
        </div>
      </div>
      <div className="flex flex-column flex-row-l justify-between items-center">
        <button
          className="button main-button w-100 w-auto-l"
          onClick={(e) =>
            nextInstance({
              e,
              errors,
              setShowErrors,
              setInstance,
              instance,
            })
          }
        >
          <b>Siguiente</b>
        </button>
        {showErrors && Object.keys(errors).length ? (
          <p className="red">Por favor completa los campos correctamente</p>
        ) : (
          <></>
        )}
      </div>
    </form>
  );
}
