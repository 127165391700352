import { useEffect, useState } from "react";
import PrincipalText from "../Principal-Text/Principaltext";
import ProductCard from "./ProductCard";
import {
  externalProducts,
  infoExternalProducts,
  infoProducts,
  listProducts,
} from "./products";
import { Link } from "react-router-dom";
import Container from "../Container/Container";
import productService from '../../services/product.service.js'
export default function SectionProducts({ userId }) {
  const [userPas, setUserPas] = useState([]);
  const productsService = new productService()
  useEffect(() => {

      productsService.getProductsPas(userId).subscribe(s=>{
        setUserPas(s.response.data);
      })
  }, [userId]);

  return (
    <Container>
      <PrincipalText />
      {userId ? (
        <div className="flex flex-wrap justify-around">
          {userPas.products?.map((p) => (
            <Link
              key={p}
              to={`/producto/${infoProducts[p].path}`}
            >
              <ProductCard props={infoProducts[p]} />
            </Link>
          ))}
        </div>
      ) : (
        <div className="flex flex-wrap justify-around">
          {listProducts.map((p) => {
            return (
              <Link
                key={p}
                to={`/producto/${infoProducts[p].path}`}
              >
                <ProductCard props={infoProducts[p]} />
              </Link>
            );
          })}
          {externalProducts.map((p) => {
            return (
              <a
                key={p}
                href={infoExternalProducts[p].path}
                target="__blank"
              >
                <ProductCard props={infoExternalProducts[p]} />
              </a>
            );
          })}
        </div>
      )}
    </Container>
  );
}
