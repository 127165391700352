import React, { useState, useContext, useEffect } from "react";
import { RecoveryContext } from "../Context/recoveryContext";
import { toast } from "react-toastify";
import Form from "../../components/FormContainer/FormContainer";
import StyledText from "../../components/StyledText/StyledText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Input/Input";
import Validate from "./validate";
import onChange from "../../utils/on-change-input";
import userService from "../../services/user.service";
import togglePassword, { toggleConfirmPassword } from "../../utils/toggle-password";

export default function Reset() {
  const service = new userService();
  const { setPage, email } = useContext(RecoveryContext);
  const [password, setPassword] = useState({
    password: "",
  });
  const [confirmPassword, setConfirmPassword] = useState({
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  async function changePassword() {
    if (errors?.password) {
      setShowErrors(true)
      return
    }
    if (password.password !== confirmPassword.password) {
      toast.warn("Las contraseñas no coinciden", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    service.updatePassword({ email, password: password.password}).subscribe();
    
    setPage("recovered");
  }

  return (
    <>
      <Form principalText>
      <div className="form-container w-100 w-60-l">
        <StyledText className="form-title" fontClasses="f4 f3-l">
          <FontAwesomeIcon className="img mr2" icon={faUnlock} />
          Cambia la contraseña
        </StyledText>
        <div className="input-couple">
          <Input
            placeholder={"Nueva contraseña *"}
            onChange={onChange}
            onClick={() => togglePassword({ setShowPassword, showPassword })}
            values={password}
            showPassword={showPassword}
            errors={errors}
            setValues={setPassword}
            setErrors={setErrors}
            showErrors={showErrors}
            type={showPassword ? "text" : "password"}
            name={"password"}
            validate={Validate}
          />
        </div>
        <div className="input-couple">
          <Input
            placeholder={"Confirme la contraseña *"}
            onChange={onChange}
            onClick={() => toggleConfirmPassword({ setShowConfirmPassword, showConfirmPassword })}
            values={confirmPassword}
            showPassword={showConfirmPassword}
            errors={errors}
            setValues={setConfirmPassword}
            setErrors={() => {}}
            showErrors={false}
            type={showConfirmPassword ? "text" : "password"}
            name={"password"}
            validate={() => {}}
          />
        </div>
        <div className="flex flex-column items-center">
          <button
            className="button main-button w-50"
            onClick={() => changePassword()}
          >
            <b>Reestablece tu contraseña</b>
          </button>
        </div>
      </div>
    </Form>
    </>
  );
}
