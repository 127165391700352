import { HttpClientCustomService } from './httpclientcustom.service.js';

export default class notificationService {
    httpClientBase;

    constructor (
        httpClient,
    ) {
        this.httpClientBase = new HttpClientCustomService(
            httpClient,
        )
    }

    getNotification(pageSearch, type, token)  {
        return  this.httpClientBase
          .get(`/product/getNotification${type ? type : ''}/${pageSearch}`, token);
    };

    postNotification(data, type, token) {
        return  this.httpClientBase
          .post(`/product/emitNotification${type ? type : ''}`, data, token);
    }
    
    deleteNotification(notificationId, type, token) {
        return  this.httpClientBase
          .delete(`/product/deleteNotification${type ? type : ''}/${notificationId}`, token);
    }
}