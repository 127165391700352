import { URLSERVER } from "../../../../config/config";
import orderService from "../../../../services/order.service";
import hopperService from "../../../../services/hopper.service";

export default function createOrderMPT({ values, jwt }) {
  const ordersService = new orderService();
  const hoppersService = new hopperService();

  const {
    name,
    lastName,
    document,
    birthdate,
    sex,
    province,
    covert,
    destiny,
    from,
    to,
    address,
    email,
    phoneNumber,
    location,
    type,
  } = values;

  ordersService.postOrderFlecha({
    name,
    lastName,
    email,
    from,
    to,
    phoneNumber,
    type,
    province,
    sex,
    birthdate,
    destiny,
    contract: covert,
    document,
    location,
    address,
  }, jwt).subscribe((s) => {
    hoppersService.postHopper(s.response.data.id,"orders_flecha",jwt).subscribe()
    if (s.status === 200) setTimeout(function(){
      window.open(`${URLSERVER}/${s.response.data.response}`)
      window.location.href = "/";
    }, 2000);
    else alert("Ocurrio un error al generar la orden");
  });
}
