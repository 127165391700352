import "./Navbar.css";
import { Link } from "react-router-dom";
import { UserMenu } from "../UserConfig/UserMenu";
import useUser from "../../hooks/UseUser";
import {
  faHome,
  faLocationDot,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Navbar() {
  const { pas } = useUser();

  return (
    <nav className="navbar justify-between bg-white flex pv2 ph2">
      <div className="mw4-l w-30-l w-10 flex-l dn items-center">
        <Link
          to={!pas?.route?.length ? "/" : `/${pas.route}`}
          className="navbar-brand"
        >
          <img src="/assets/logo.png" alt="#" className="w-100" />
        </Link>
      </div>
      <div className="mw4 w-30-l w-25 flex dn-l items-center">
        <Link to={!pas ? "/" : `/usuario_pas/${pas.id}`}>
          <img src="/assets/logo.png" alt="#" className="w-100" />
        </Link>
      </div>
      <div className="flex w-70-l w-90 justify-around items-center">
        <span className="nav-item flex justify-center">
          {!pas?.route?.length ? (
            <Link to="/" className="nav-link active">
              <span className="flex-l dn">Inicio</span>
              <FontAwesomeIcon
                className="flex dn-l pb1 h1-25rem"
                icon={faHome}
              />
            </Link>
          ) : (
            <Link to={`/${pas.route}`} className="nav-link active">
              <span className="flex-l dn">Inicio</span>
              <FontAwesomeIcon
                className="flex dn-l pb1 h1-25rem"
                icon={faHome}
              />
            </Link>
          )}
        </span>
        <span className="nav-item flex justify-center">
          {!pas?.route?.length ? (
            <a href="/#quienes-somos" className="nav-link">
              <span className="flex-l dn">¿Quiénes somos?</span>
              <FontAwesomeIcon
                className="flex dn-l pb1 h1-25rem"
                icon={faUsers}
              />
            </a>
          ) : (
            <a href={`/${pas.route}/#quienes-somos`} className="nav-link">
              <span className="flex-l dn">¿Quiénes somos?</span>
              <FontAwesomeIcon
                className="flex dn-l pb1 h1-25rem"
                icon={faUsers}
              />
            </a>
          )}
        </span>
        <span className="nav-item flex justify-center">
          {!pas?.route?.length ? (
            <a href="/#contacto" className="nav-link">
              <span className="flex-l dn">Contacto y Ubicación</span>
              <FontAwesomeIcon
                className="flex dn-l pb1 h1-25rem"
                icon={faLocationDot}
              />
            </a>
          ) : (
            <a href={`/${pas.route}/#contacto`} className="nav-link">
              <span className="flex-l dn">Contacto y Ubicación</span>
              <FontAwesomeIcon
                className="flex dn-l pb1 h1-25rem"
                icon={faLocationDot}
              />
            </a>
          )}
        </span>
        <span className="nav-item flex justify-center">
          <UserMenu />
        </span>
      </div>
    </nav>
  );
}
