export default function PrincipalText() {
  return (
    <div className="principal-text tc pv1 ph3">
      <h3 className="f3-l f4 b">El seguro al alcance de todos</h3>
      <style jsx>{`
        .principal-text h3 {
          color: var(--color-first-medium);
        }
      `}</style>
    </div>
  );
}
