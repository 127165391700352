import "./index.css";
import StyledText from "../StyledText/StyledText";

export default function ClientSummary({ children }) {
  return (
    <div className="client-summary w-90 w-75-l flex items-center flex-column mb3 center">
      <StyledText className="form-title" fontClasses="f5 f4-m f3-l">
        Resumen de la cobertura
      </StyledText>
      <div className="w-100 flex justify-between">{children}</div>
    </div>
  );
}
