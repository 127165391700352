export const ModalCreateProductsGroups = ({title,data,handleChange}) => {
  return (
      <>
      <div style={{display:"flex", flexDirection:"column", gap:10}}>
            <h4>{title}</h4>
            <label>Descripción:</label>
            <input type="text" name="description" style={{height:"25px", border:"solid 1px"}} value={data.description} onChange={(e)=>handleChange(e)}/>
          </div>
      </>
  )
} 