import { HttpClientCustomService } from './httpclientcustom.service.js';

export default class userService {
    httpClientBase;


    constructor() {
        this.httpClientBase = new HttpClientCustomService()
    }


    postLogin(user) {
        return this.httpClientBase
            .post(`/user/loginUser`, user, null)
    };

    postUser(values) {
        return this.httpClientBase
            .post(`/user/postUser`,values)
    }
    
    postSendEmail(mailData) {
        return this.httpClientBase
            .post(`/mail/sendEmail`, mailData)
    };

    postSendEmailMP(mailData) {
        return this.httpClientBase
            .post(`/mail/sendEmail/MP`, mailData)
    };

    updatePassword(user) {
        return this.httpClientBase
            .put(`/user/updatepass`, user)
    };

    getPersonalData(token)  {
        return  this.httpClientBase
          .get(`/user/myPersonalData`, token);
      };
      
    getClientsOfPas(page, jwt) {
        return this.httpClientBase
            .get(`/user/getClientsOfPas/${page}`, jwt)
    }

    getAllUsers(page, jwt) {
        return this.httpClientBase
            .get(`/user/getAllUsers/${page}`, jwt)
    }

    getUserByEmail(email, jwt) {
        return this.httpClientBase
            .get(`/user/searchUserByEmail/${email}`, jwt)
    }

    getMail(mail) {
        return this.httpClientBase
            .get(`/user/getemail?${mail}`)
    }

    myPersonalData(token) {
        return this.httpClientBase
            .get(`/user/myPersonalData`,token);
    };
    
    updateUser(type , id, token) {
        return this.httpClientBase
            .put(`/user/updateUser/${type}/${id}`,null,token);
    };

    updateUserInfo(data,token) {
        return this.httpClientBase
            .put(`/user/updateUserInfo`,data,token);
    }

    getEmiter(page, token) {
        return this.httpClientBase
        .get(`/user/emiter?page=${page}`,token);
    }

    getEmiterId(id, token) {
        return this.httpClientBase
        .get(`/user/emiter/${id}`,token);
    }

    getEmiterByUserId(id, token) {
        return this.httpClientBase
        .get(`/user/emiter/userId/${id}`,token);
    }

    deleteEmiter(id, enabled, token) {
        return this.httpClientBase
        .delete(`/user/emiter/${id}/${enabled}`,token);
    }

    putEmiter(data, token) {
        return this.httpClientBase
        .put(`/user/emiter`, data, token);
    }

    putEmiterActiveByUserId(data, token) {
        return this.httpClientBase
        .put(`/user/emiter/active/${data.userId}`, data, token);
    }

    postEmiter(data, token){
        return this.httpClientBase
        .post(`/user/emiter`, data, token);
    }
    
    getPasByRoute(route) {
        return this.httpClientBase
        .get(`/user/getPasByRoute/${route}`)
    }

    imageLg(id) {
        return this.httpClientBase
        .get(`/user/imageLg/${id}`)
    }
}