export default function regexForForms() {
  const textRegex = /^[a-zA-Z\s]*[a-zA-Z]{3,}[a-zA-Z\s]*$/;
  const addressRegex = /\b[A-Za-z]{2,}\b/;
  const destinyRegex = /^[a-zA-Z\s]*[a-zA-Z]{4,}[a-zA-Z\s]*$/;
  const emailRegex = /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/;
  const documentOrPassportRegex = /^\s*(?:\d{7,8}|[A-Z]{1,2}\d{7})\s*$/;
  const phoneNumberRegex = /^\s*[0-9]{7,}\s*$/;
  const isValidDepartureDate = (departureDate) => {
    const selectedDate = new Date(departureDate);
    const today = new Date();
    selectedDate.setDate(selectedDate.getDate() + 1);

    return selectedDate >= today;
  };
  const isValidReturnDate = (departureDate, returnDate) => {
    const startDate = new Date(departureDate);
    const endDate = new Date(returnDate);

    return startDate < endDate;
  };
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$#@$!%*?&])([A-Za-z\d$@$!%*?&]|[^]){8,15}$/;

  return {
    textRegex,
    addressRegex,
    destinyRegex,
    emailRegex,
    documentOrPassportRegex,
    phoneNumberRegex,
    isValidDepartureDate,
    isValidReturnDate,
    passwordRegex
  };
}
