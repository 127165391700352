import React from "react";
import { useNavigate } from "react-router-dom";
import Form from "../FormContainer/FormContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StyledText from "../StyledText/StyledText";
import { faCheck, faUnlock } from "@fortawesome/free-solid-svg-icons";

export default function Recovered() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/ingresar");
  };
  return (
    <>
      <Form principalText>
        <div className="form-container w-100 w-60-l">
          <StyledText className="form-title" fontClasses="f4 f3-l">
            <FontAwesomeIcon className="img mr2" icon={faCheck} />
            Cambiaste tu contraseña con éxito!
          </StyledText>
          <div className="flex flex-column items-center">
            <button
              className="button main-button w-50"
              onClick={handleClick}
            >
              <b>Iniciar Sesión</b>
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}