import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { RecoveryContext } from "../Context/recoveryContext";
import { toast } from "react-toastify";
import Form from "../../components/FormContainer/FormContainer";
import StyledText from "../../components/StyledText/StyledText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Input/Input";
import Validate from "../../pages/Login/loginUtils/validate";
import { useNavigate } from "react-router";
import userService from "../../services/user.service";
import onChange from "../../utils/on-change-input";

export default function OTPinput() {
  const service = new userService();
  const { email, otp, setPage } = useContext(RecoveryContext);
  const [timerCount, setTimer] = React.useState(60);
  const [disable, setDisable] = useState(true);
  const [values, setValues] = useState({
    code: ""
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  function resendOTP() {
    if (disable) return;
    const mailData = {
      subject: `Recupera tu contraseña`,
      recipient_email: email,
      inner_mail: `<!DOCTYPE html><html lang="en" ><head><meta charset="UTF-8"><title>Recuperá tu contraseña</title></head><body><!-- partial:index.partial.html --><div style="font-family: Helvetica,Arial,sans-serif;min-width:1000px;overflow:auto;line-height:2"><div style="margin:50px auto;width:70%;padding:20px 0"><div style="border-bottom:1px solid #eee"><a href="" style="font-size:1.4em;color: #00466a;text-decoration:none;font-weight:600">RUS</a></div><p style="font-size:1.1em">Hola,</p><p>ingresá este código de validación para cambiar tu contraseña! El código vence en 5 minutos</p><h2 style="background: #00466a;margin: 0 auto;width: max-content;padding: 0 10px;color: #fff;border-radius: 4px;">${otp}</h2><p style="font-size:0.9em;">Saludos!</p><hr style="border:none;border-top:1px solid #eee" /><div style="float:right;padding:8px 0;color:#aaa;font-size:0.8em;line-height:1;font-weight:300"><p>Equipo de <a style="text-decoration: none" href="https://rus.com.ar">RUS</a></p></div></div></div><!-- partial --></body></html>`,
      attachFile: false,
    };
    service.postSendEmail(mailData).subscribe();
  }

  function verfiyOTP() {
    if (values.code === otp.toString()) {
      setPage("reset");
      return;
    }
    toast.error("El código que ingresaste no es válido", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return;
  }

  React.useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) setDisable(false);
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [disable]);

  return (
      <Form principalText>
      <div className="form-container w-100 w-60-l">
        <StyledText className="form-title" fontClasses="f4 f3-l">
        <FontAwesomeIcon className="img mr2" icon={faKey} />
        Verifiquemos tu email
        </StyledText>
        <StyledText className="" fontClasses="f5 f5-l">
          Te enviamos un mail a: {email}
        </StyledText>
        <div className="input-couple">
          <Input
            placeholder={"Inserte el código *"}
            onChange={onChange}
            values={values.code}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            showErrors={showErrors}
            type={"code"}
            name={"code"}
            validate={Validate}
          />
        </div>
        <div className="flex flex-column items-center">
          <button
            className="button main-button w-50"
            onClick={() => verfiyOTP()}
          >
            <b>Verificar tu cuenta</b>
          </button>
          <span className="tc">
            ¿No recibiste tu código?{" "}
            <button
              className="button button-nohover"
              onClick={() => resendOTP()}
            >
              {disable ? `Reenvia tu codigo ${timerCount}s` : "Reenviar código"}
            </button>
          </span>
        </div>
      </div>
    </Form>
  );
}
