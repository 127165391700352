import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

export default function FileInput({
  handleFileChange,
  handleOpenModal,
  selectedFile,
  order,
  fileStatus,
}) {
  const filename = selectedFile.file?.name.substring(0, 10) + "...";
  const actualOrderCode = order?.nr_cert;
  const selectedFileOrderCode = selectedFile?.order?.nr_cert;
  const isDisabledInput =
    selectedFile.file && selectedFileOrderCode !== actualOrderCode;

  return (
    <div className="file-input-container flex justify-center items-center">
      <input
        id={actualOrderCode}
        className="o-0"
        style={{ width: "0px" }}
        type="file"
        name="file"
        onChange={(e) => handleFileChange(e, order)}
        disabled={isDisabledInput}
      />

      {!selectedFile.file || selectedFileOrderCode !== actualOrderCode ? (
        <label
          className={`button ${
            isDisabledInput ? "file-label-disabled" : "file-label"
          }`}
          htmlFor={actualOrderCode}
        >
          <b>Cargar póliza</b>
        </label>
      ) : (
        selectedFileOrderCode === actualOrderCode && (
          <button
            className="button main-button"
            onClick={() => handleOpenModal()}
          >
            {fileStatus === 1 ? (
              <b>
                <FontAwesomeIcon icon={faUpload} /> {filename}
              </b>
            ) : fileStatus === 2 ? (
              <b>Cargando...</b>
            ) : (
              fileStatus === 3 && <b>Póliza cargada</b>
            )}
          </button>
        )
      )}
      <style jsx>{`
        .file-label {
          padding: 0.5rem;
          border: none;
          border-radius: 0.5rem;
          cursor: pointer;
          transition: all 0.2s;
          background-color: var(--color-first-medium);
          color: #fff;
        }
        .file-label-disabled {
          background-color: #d9d9d9;
          cursor: default;
          color: #a7a7a7;
        }
        @media screen and (min-width: 60em) {
          .file-label:hover {
            background-color: var(--color-first-light);
          }
        }
      `}</style>
    </div>
  );
}
