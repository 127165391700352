export default function NoResultsText() {
  return (
    <div className="flex justify-center items-center h80 tc mb1 mt3">
      <span className="tc b f4-m f4-l f5">Sin resultados</span>
      <style jsx>{`
        .h80 {
          height: 80%;
        }
        span {
          color: var(--color-first-medium)
        }
      `}</style>
    </div>
  );
}
