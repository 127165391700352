import CreateOrderButton from "../../../components/CreateOrderButton/CreateOrderButton";
import MPButton from "../../../components/MPButton/MPButton";
import CopyMPLink from "../../../components/CopyMPLink/CopyMPLink";
import StyledText from "../../../components/StyledText/StyledText";
import SummaryCard from "../../../components/ClientSummary/SummaryCard";
import ClientSummary from "../../../components/ClientSummary/ClientSummary";
import backInstance from "./formUtils/back-instance";
import trimValues from "../../../utils/trim-values";
import createPayBP from "./formUtils/create-pay";
import createOrderBP from "./formUtils/create-order";
import copyMPLinkBP from "./formUtils/copy-mp-link";
import { userIsPas } from "../../../utils/user-is-type";
import BackButton from "../../../components/BackButton/BackButton";
import useUser from "../../../hooks/UseUser";
import { Fragment, useState } from "react";

export default function BPSummary({ values, setInstance, instance }) {
  const { user } = useUser();
  const trimmedValues = trimValues(values);
  const {
    name,
    lastName,
    document,
    birthdate,
    sex,
    province,
    covert,
    address,
    email,
    phoneNumber,
    location,
  } = trimmedValues;
  const isPas = userIsPas(user);

  return (
    <Fragment>
      <ClientSummary>
        <SummaryCard>
          <StyledText className="dark-text f-medium" fontClasses="f5 f4-m f3-l">
            Bolso Protegido
          </StyledText>
          <hr />
          <div className="flex flex-column">
            <p>
              <b>Cobertura: </b>
              {JSON.parse(covert).description}
            </p>
            <p>
              {name} {lastName}
            </p>
            <p>
              {document}, {birthdate}
            </p>
            <p>
              {email}, {phoneNumber}
            </p>
            <p>{sex}</p>
            <p>
              {province}, {location}
            </p>
            <p>{address}</p>
            <p
              style={{
                color: "var(--color-first-medium",
                textDecoration: "underline",
              }}
            >
              * La contratación mínima son 6 meses
            </p>
          </div>
        </SummaryCard>
      </ClientSummary>
      <div
        className={`w-90 w-75-l mt3 flex flex-column flex-row-l center justify-between`}
      >
        <BackButton
          backInstance={backInstance}
          setInstance={setInstance}
          instance={instance}
        />
        {isPas ? (
          <div className="flex flex-column flex-row-l">
            <CreateOrderButton
              values={trimmedValues}
              handleCreateOrder={createOrderBP}
            />
            <CopyMPLink values={trimmedValues} handleCopy={copyMPLinkBP}/>
          </div>
        ) : (
          <MPButton values={trimmedValues} handleCreatePay={createPayBP} />
        )}
      </div>
    </Fragment>
  );
}
