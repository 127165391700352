import InfiniteScroll from "react-infinite-scroll-component"
import { SearchbarTable } from "../../Searchbar/SearchbarTable"
import UserInfoRow from "../TableSearchUser/UserInfoRow"
import LoaderSpinner from "../../../LoaderSpinner/LoaderSpinner"
import NoResultsText from "../../../NoResultsText/NoResultsText"
import { useCallback, useEffect, useState } from "react"
import userService from "../../../../services/user.service"
import useUser from "../../../../hooks/UseUser"
import { Modal } from "../../../Modal/Modal"

export const ModalCreateEmiter = ({data}) => {
    const [page, setPage] = useState(1);
    const [input, setInput] = useState("");
    const [users, setUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [data2,setData2] = useState()
  const serviceUser = new userService()

    const handleClose = () =>{ 
      setShowModalUpdate(false)
    }
    const handleOpenModalUpdate = (e) => {
        setData2(e)
        setShowModalUpdate(true)
      };
    const { jwt } = useUser();
    const handleSubmitSearch = useCallback(
        (input) => {
          if (input.trim().length) {
            serviceUser.getUserByEmail(input.trim(), jwt).subscribe((s) => {
              setUser(s.data);
            });
          }
        },
        [jwt]
      );
      const handleConfirmCreate = () => {
        serviceUser.postEmiter(data2, jwt).subscribe()
      }
      const fetchAllUsers = useCallback(() => {
        setLoading(true);
        serviceUser.getAllUsers(page, jwt).subscribe((s) => {
          setLoading(false);
          setUser(users.concat(s.data.users));
        });
      }, [jwt, page,input]);

    useEffect(() => {
    if (input.trim().length) handleSubmitSearch(input);
    }, [page, input, handleSubmitSearch, fetchAllUsers]);
    return (
        <>
        {showModalUpdate && 
          <Modal handleClose={handleClose} handleConfirm={handleConfirmCreate} cruzButton>
                <h4>Seguro que desea actualizar a {data2.email} a emisor</h4>
          </Modal>
          }
        <div style={{display:"flex", flexDirection:"column", gap:10}}>
              <h4>Crear emisor</h4>
              <div className="w-100 h-100 pre" id="InfiniteScroll">
            <div className="flex justify-between-l justify-center pv2">
                <SearchbarTable
                setInput={setInput}
                handleSubmitSearch={handleSubmitSearch}
                input={input}
                />
            </div>
            <InfiniteScroll
                dataLength={users.length}
                next={() => setPage((prev) => prev + 1)}
                hasMore={true}
                scrollableTarget="InfiniteScroll"
            >
                <table>
                <thead>
                    <th scope="col">Nombre</th>
                    <th scope="col">Email</th>
                    <th scope="col">Tipo usuario</th>
                </thead>
                <tbody>
            {users.map((e, i) => {

              return e.type === "admin" || e.type === "superadmin" ? <tr className="dim pointer" onClick={()=>handleOpenModalUpdate(e)} key={i}>
                <td>{e?.name} {e?.last_name}</td>
                <td>{e?.email}</td>
                <td>{e?.type}</td>
              </tr> :
              <></>}
            )}
          </tbody>
                </table>
            </InfiniteScroll>
            {loading && <LoaderSpinner />}
            {!loading && !users.length && <NoResultsText />}
            </div>
            </div>
        </>
    )
} 