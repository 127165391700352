import { useCallback, useContext } from "react";
import Context from "../components/Context/userContext";
import userService from "../services/user.service";

export default function useUser() {
  const service = new userService();
  const { jwt, setJWT, user, setUser, tokenPas, site, setSite } =
    useContext(Context);
  const login = useCallback(
    (data) => {
      service.postLogin(data).subscribe((resp) => {
        if(resp.response.token !== undefined){
          setJWT(resp.response.token);
          setUser(resp.data);
          window.sessionStorage.setItem("jwt", resp.response.token);
          window.sessionStorage.setItem("user", JSON.stringify(resp.data));
          return { token: resp.response.token, user:resp.data };
        } else {
          setJWT();
          setUser();
          window.sessionStorage.clear();
        }
      });
    
    },
    [service, setJWT, setUser]
  );

  const updateSite = (value) => {
    setSite(value);
  };

  const logout = useCallback(() => {
    if(user?.type === "admin" || user?.type === "superadmin"){
      service.getEmiterByUserId(user.id, jwt).subscribe((s)=>{
        service.putEmiter({active:0, emiterId: s.data[0].emiterId}, jwt).subscribe()
      })
    }
    window.sessionStorage.removeItem("jwt");
    window.sessionStorage.removeItem("user");
    setJWT(null);
    setUser(null);
    // window.location.reload();
  }, [setJWT]);

  return {
    isLoggedIn: Boolean(jwt),
    login,
    logout,
    user,
    jwt,
    tokenPas,
    updateSite,
    site,
  };
}