import React from "react";
import useUser from "../../hooks/UseUser";
import "../../style.css";

export default function MPButton({ values, handleCreatePay }) {
  const { user } = useUser();
  return (
    <button
      className="button mp-button flex items-center justify-center"
      style={{paddingBottom: 0, paddingTop: 0}}
      onClick={() => handleCreatePay({ values, user })}
    >
      <b>Pagar</b>
      <span className="span-icon mp-icon flex ml1"></span>
    </button>
  );
}
