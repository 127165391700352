import { toast } from "react-toastify";
import { retry, catchError, map } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

export class HttpClientCustomService {
    retryCount = 2;
    get(url, token) {
        const config = {
            method: "get",
            url: `${process.env.REACT_APP_API}${url}`,
            headers: { token: token },
        };
        const data = ajax(config).pipe(
            map(resp => {
                return{
                    data: resp.response.data,
                    status: resp.status,
                    request: resp.request,
                    response: resp.response,
                }
            }),
            retry(this.retryCount),
            catchError((err,{response}) => {
                if(err?.status == 403) {
                    toast.info(`${response ? response : "Acceso no autorizado!"}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    return of()
                }
                if(err?.message !== "Failed to execute 'open' on 'XMLHttpRequest': Invalid URL")
                toast.info(`${response ? response : "Ups, algo salio mal!"}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                return of()
            }
        ))
        return data
    }

    post(url, item, token) {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_API}${url}`,
            headers: { token: token },
            body: item
        };
        const data = ajax(config).pipe(
            map(resp => {
                if(resp.status>=200 && resp.status<300){
                    toast.success("Exitoso", {
                    position: "bottom-center",
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    pauseOnHover: true,
                    theme: "light",
                    autoClose: 5000,
                    });
                }
                return {
                    data: resp.response.result,
                    status: resp.status,
                    request: resp.request,
                    response: resp.response,
                }
            }),
            catchError(({response}) => {
                toast.info(`${response?.message}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                return of()
            }
        ))
        return data
    }

    put(url, item, token) {
        const config = {
            method: "put",
            url: `${process.env.REACT_APP_API}${url}`,
            headers: { token: token },
            body: item
        };
        const data = ajax(config).pipe(
            map(resp => {
                if(resp.status>=200 && resp.status<300){
                    toast.success("Exitoso", {
                    position: "bottom-center",
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    pauseOnHover: true,
                    theme: "light",
                    autoClose: 5000,
                    });
                }
                return {
                    data: resp.response.result,
                    status: resp.status,
                    request: resp.request,
                    response: resp.response,
                }
            }),
            catchError((error) => {
                toast.info(`${error?.message}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                return of()
            }
        ))
        return data
    }

    delete(url, token) {
        const config = {
            method: "put",
            url: `${process.env.REACT_APP_API}${url}`,
            headers: { token: token }
        };
        const data = ajax(config).pipe(
            map(resp => {
                if(resp.status>=200 && resp.status<300){
                    toast.success("Exitoso", {
                    position: "bottom-center",
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    pauseOnHover: true,
                    theme: "light",
                    autoClose: 5000,
                    });
                }
                return {
                    data: resp.response.result,
                    status: resp.status,
                    request: resp.request,
                    response: resp.response,
                }
            }),
            retry(this.retryCount),
            catchError(({response}) => {
                toast.info(`${response.message}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                return of()
            }
        ))
        return data
    }
}
