import { URLSERVER } from "../../../../config/config";
import orderService from "../../../../services/order.service";
import hopperService from "../../../../services/hopper.service";

export default function createOrderBP({ values, jwt }) {
  const ordersService = new orderService();
  const hoppersService = new hopperService();
  const {
    name,
    lastName,
    document,
    birthdate,
    sex,
    province,
    covert,
    address,
    email,
    phoneNumber,
    type,
    location,
  } = values;

  ordersService.postEmitBPPas({
    name,
    lastName,
    document,
    birthdate,
    sex,
    province,
    covert,
    address,
    email,
    phoneNumber,
    type,
    location,
  }, jwt).subscribe((s) => {
    hoppersService.postHopper(s.response.data.id,'orders_bag_flecha',jwt).subscribe()
    if (s.status === 200) setTimeout(function(){
      window.open(`${URLSERVER}/${s.response.data.response}`)
      window.location.href = "/";
    }, 4000);
    else alert("Ocurrio un error al generar la orden");
  });
}
