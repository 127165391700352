const togglePassword = ({ setShowPassword, showPassword }) => {
  setShowPassword(!showPassword);
};

export const toggleConfirmPassword = ({ setShowConfirmPassword, showConfirmPassword }) => {
  setShowConfirmPassword(!showConfirmPassword);
};


export default togglePassword;
