import { faArrowLeft, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BackButton({
  backInstance,
  setInstance,
  instance,
  handleClick,
  text,
  className,
  arrow = true,
  cruzIcon,
}) {
  return (
    <button
      className={`button back-button mh1 ${className}`}
      onClick={(e) => backInstance({e, setInstance, instance})}
    >
      {cruzIcon ? (
        <FontAwesomeIcon icon={faX} className="cruz-icon" />
      ) : (
        <>
          {arrow && <FontAwesomeIcon icon={faArrowLeft} className="mr2" />}
          <b>{text || "Atrás"}</b>
        </>
      )}
      <style jsx>{`
        .cruz-icon {
          height: 1.3rem;
          color: #000;
        }
      `}</style>
    </button>
  );
}
