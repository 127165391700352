import userService from "../../../services/user.service";

const registerSession = async ({ e, values, errors, setShowErrors, navigate }) => {
  const userServices = new userService()
  e.preventDefault();
  const canInitSession = !(Object.keys(errors).length);
  setShowErrors(!canInitSession);
  if (canInitSession) {
    userServices.postUser(values).subscribe(()=>{
      navigate("/ingresar");
    })
  }
};

export default registerSession;
