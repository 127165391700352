import React, { useState } from "react";
import useUser from "../../hooks/UseUser";
import { Modal } from "../Modal/Modal";

export default function CreateOrderButton({ values, handleCreateOrder }) {
  const { jwt } = useUser();
  const [isClicked, setIsClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    setShowModal(true);
  };
  
  const handleClose = () =>{ 
    setIsClicked(false);
    setShowModal(false)
  }
  
  const handleConfirmCreate = () => {
    handleCreateOrder({ values, jwt });
  }


  return (
    <>
    <button
      className={`button main-button mh1-l mv0-l mv1 ${isClicked ? 'disabled' : ''}`}
      onClick={handleClick}
      disabled={isClicked}
    >
      <b>Emitir certificado</b>
    </button>
     {showModal && 
      <Modal handleClose={handleClose} handleConfirm={handleConfirmCreate} cruzButton>
            <h4>Seguro que desea actualizar emitir poliza?</h4>
      </Modal>
      }
    </>
  );
}
