import { useCallback } from "react";
import useUser from "../../../../hooks/UseUser";
import productService from "../../../../services/product.service";
import hopperService from "../../../../services/hopper.service";

export const useOrder = ({
  page,
  orders,
  setLoading,
  setOrders,
  setSelectedFile,
  selectedFile,
  setFileStatus,
  handleSubmitSearch,
  input,
  setShowModalUploadPolicies,
}) => {
  const { user, jwt } = useUser();
  const hopperServices = new hopperService()
  const productServices = new productService();
  const fetchPASOrders = useCallback(() => {
    setLoading(true);
    productServices
      .getMyProductsSale(user.id_user, page, jwt)
      .subscribe((s) => {
        setLoading(false);
        setOrders(orders.concat(s.data?.result));
      });
  }, [page]);

  const fetchAllOrders = useCallback(() => {
    setLoading(true);
    productServices.getAllOrders(page, jwt).subscribe((s) => {
      setLoading(false);
      setOrders(orders.concat(s.data.products));
    });
  }, [jwt, setLoading, setOrders, page]);

  const fetchClientOrders = useCallback(() => {
    setLoading(true);
    productServices.getClientOrders(page, jwt).subscribe((s) => {
      setLoading(false);
      setOrders(orders.concat(s.data.products));
    });
  }, [jwt, setLoading, setOrders, page]);


  const issueOrder = (nr_cert) => {
    productServices.putIssureOrder({ nr_cert }, jwt).subscribe(() => {
      fetchAllOrders();
    });
  };

  const disableOrder = (nr_cert) => {
    productServices.putDisableOrder({ nr_cert }, jwt).subscribe(() => {
      fetchAllOrders();
    });
  };

  const handleFileChange = (event, order) => {
    const file = event.target.files[0];
    setFileStatus(1);
    setSelectedFile({ file, order });
  };

  const handleUpload = (order) => {
    if (selectedFile) {
      setFileStatus(2);
      const formData = new FormData();
      formData.append("poliza", selectedFile.file);
      productServices.loadPdf(formData,order.nr_cert,jwt).subscribe((s) => {
        if (s.response.data) {
            setFileStatus(3);
            setTimeout(() => {
                setSelectedFile({ file: null, order: null });
              }, 1000);
              productServices.getAllOrders(page, jwt).subscribe((s) => {
                setLoading(false);
                setOrders(s.data.products);
              });
            hopperServices.putHopperBySource({data:{orderId:order.id,source:order.source}},jwt).subscribe()
          } else {
            setFileStatus(0);
            setTimeout(() => {
                setSelectedFile({ file: null, order: null });
              }, 1000);
        }
        })
    }
  };

  const handleOpenModal = () => {
    setShowModalUploadPolicies(true);
  };
  const handleCloseModal = () => {
    setSelectedFile({ file: null, order: null });
    setShowModalUploadPolicies(false);
  };

  return {
    fetchPASOrders,
    fetchAllOrders,
    fetchClientOrders,
    issueOrder,
    disableOrder,
    handleFileChange,
    handleUpload,
    handleOpenModal,
    handleCloseModal,
  };
};
