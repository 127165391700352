import regexForForms from "../../utils/regex-forms";

export default function Validate(values) {
  let errors = {};
  const { passwordRegex } = regexForForms();

  if (!values.password) {
    errors.password = "→ Ingrese una contraseña";
  } else if (!passwordRegex.test(values.password)) {
    errors.password =
      "→ 8 a 15 caracteres - Al menos una letra mayúscula - Al menos un dígito numérico - Sin espacios - Al menos un carácter especial.";
  }
  
  return errors;
}
