import { URLSERVER } from "../../config/config";
import "./index.css";

export default function WeInfo({ description, name }) {
  return (
    <div className="weinfo tc center pt4" id="quienes-somos">
      <div className="weinfo-img bg-white center pa3 br4">
        <img src={`${URLSERVER}/rus.png`} alt="#" />
      </div>
      <div className="white">
        <h1 className="ph3 center f3">{name}</h1>
        <p className="ph3 center f6 f5-l">{description}</p>
      </div>
    </div>
  );
}
